import React from "react";
import Flag from "react-world-flags";

type IProps = {
  code: string;
  region_name?: string;
};
export default function FlagItem(props: IProps) {
  return (
    <span
      className="d-flex"
      style={{ flexDirection: "row", alignItems: "center", columnGap: "5px"}}
    >
       <Flag
          code={props.code}
          height="20"
          width={"20"}
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
      <span
        className="d-flex"
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          alignItems: "center",
          overflowX: "clip",
          height:"20px"
        }}
      >
        {props.region_name && <span>{props.region_name || ""}</span>}
      </span>
    </span>
  );
}
