import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  EntityAttachementEnquiry,
  InputFormEnquiries,
} from '../../../../@types/enquiries';
import { EntityMandate } from '../../../../@types/mandates/mandates';
import {
  concatString,
  exportedDropdownValue,
  isExist,
  makeOptions,
} from '../../../../utils/helper-function';
import { DropDownOptionObjectType } from '../../../common/DropDown/DropdownSelect';
import SelectDropDown from '../../../common/DropDown/SelectDropDown';
import { LegalEntity } from '../../../../@types/group/types';
import { FunctionName, TermConst } from '../../../../@types/common-types';
import UseConstante from '../../../../hooks/use-constante';
import { useGetCountryQuery } from '../../../../redux/api/ws/databases/groups';
import { mockTenor } from '../../../../utils/mockTenor';
import { useGetOwnerQuery } from '../../../../redux/api/ws/mandates/mandates';
import { ConterParty } from '../../../../@types/conterParty';
import { People } from '../../../../@types/people';
import { RiFolderUploadFill } from 'react-icons/ri';

import { ReactComponent as MandateRoundedInactif } from '../../../../assets/img/MandateRoundedInactif.svg';
import { ReactComponent as MandateRoundedActif } from '../../../../assets/img/MandateRoundedActif.svg';

import { ReactComponent as ClientInactif } from '../../../../assets/img/ClientInactif.svg';
import { ReactComponent as ClientActive } from '../../../../assets/img/ClientActive.svg';

import { ReactComponent as LegalentitiesActif } from '../../../../assets/img/LegalentitiesActif.svg';
import { ReactComponent as LegalentitiesInactif } from '../../../../assets/img/LegalentitiesInactif.svg';

import { ReactComponent as ObligorInActif } from '../../../../assets/img/ObligorInActif.svg';
import { ReactComponent as ObligorActif } from '../../../../assets/img/ObligorActif.svg';

import { ReactComponent as TeamInactif } from '../../../../assets/img/TeamInactif.svg';
import { ReactComponent as TeamActif } from '../../../../assets/img/TeamActif.svg';

import { ReactComponent as PeopleIncatif } from '../../../../assets/img/PeopleInactif.svg';
import { ReactComponent as PeopleActif } from '../../../../assets/img/PeopleActif.svg';
import { ReactComponent as PartnerIncatif } from '../../../../assets/img/PartnerIncatif.svg';
import { ReactComponent as PartnerActif } from '../../../../assets/img/PartnerActif.svg';
import CofarcoFormNumber from '../../../Form/CofarcoFormNumber';
import ColumnUpload from './ColumnUpload';
import DeleteModal from '../../../Modal/DeleteModal';
import { useDeleteEnquiryAttachmentMutation } from '../../../../redux/api/ws/enquiries/enquiries';
import CofarcoNumberPercentage from '../../../Form/CofarcoNumberPercentage';
import { Table } from 'antd';
import RowContent from '../../../Form/RowContent';
import CofarcoCalendar from '../../../Calendar/CofarcoCalendar';
import ModalUnsavedChange from '../../../common/Modal/ModalUnsavedChange';

type IProps = {
  mandateListe: Array<EntityMandate>;
  watch: UseFormWatch<InputFormEnquiries>;
  setValue: UseFormSetValue<InputFormEnquiries>;
  runSearch: (key: keyof InputFormEnquiries, value: string) => Promise<void>;
  setFormError: UseFormSetError<InputFormEnquiries>;
  register: UseFormRegister<InputFormEnquiries>;
  errors: FieldErrors<InputFormEnquiries>;
  clearFormErrors: UseFormClearErrors<InputFormEnquiries>;
  selectedMandate: EntityMandate | undefined;
  legalEntityList: Array<LegalEntity>;
  selectedMandateById: EntityMandate | null;
  control: Control<InputFormEnquiries, any>;
  partenerList: Array<ConterParty>;
  partenersPeople: Array<People>;
  showMandate: (id: string | number) => void;
  //showClient: (id: number, type?: string) => void;
  showLegalEntity: (id: number | null) => void;
  showCofarcoTeams: (id: string | number) => void;
  showLegalEntityClientId: () => void;
  showPartner: (id: number) => void;
  showPeople: (id: number) => void;
  showTopObligorEntity: () => void;
  isLoadingAttachement: boolean;
  onAddAttachment: (files: FileList | null) => void;
  hiddenFooter: boolean;
  isPartenerLoading: boolean;
  isMandateLoading: boolean;
  handleShowOtherModal: (id: any, func: FunctionName, type?: string) => void;
  handleLeaveModalWithoutSave: (
    id: any,
    func: FunctionName,
    type?: string,
  ) => void;
  showOtherModal: any;
  provider: any;
};
export default function ChildreenForm({
  provider,
  showOtherModal,
  handleShowOtherModal,
  ...props
}: IProps) {
  const [modalDeleteAttach, setModalDeleteAttach] = useState<boolean>(false);
  const [selectedAttachment, setSelectedAttachment] =
    useState<EntityAttachementEnquiry>();

  const mandate_id = props.watch('mandate_id');
  const client_legal_entity_id = props.watch('client_legal_entity_id');
  const market = props.watch('market');
  const partner_id = props.watch('partner_id');
  const enquiry_type = props.watch('enquiry_type');
  const risk_trigger = props.watch('risk_trigger');
  const exposure_currency = props.watch('exposure_currency');
  const individual_currency = props.watch('individual_currency');
  const protection_unit = props.watch('protection_unit');
  const status = props.watch('status');
  const outcome = props.watch('outcome');
  const lead_user_id = props.watch('lead_user_id');
  const support1_user_id = props.watch('support1_user_id');
  const support2_user_id = props.watch('support2_user_id');
  const partner_contact_id = props.watch('partner_contact_id');
  const exposure_gross = props.watch('exposure_gross') as number;
  const individual_limits = props.watch('individual_limits') as number;
  const protection = props.watch('protection') as number;
  const id = props.watch('id');

  const terms = UseConstante();

  const { data: responseCountry } = useGetCountryQuery();

  const { data: ownerList } = useGetOwnerQuery();

  const enquiriesOptions = () => {
    if (props.selectedMandate?.mandate_type === 'financing') {
      return terms
        .getConst(TermConst.dealMarket)
        ?.filter((el: any) => el.dependences === 'financing');
    } else {
      return terms
        .getConst(TermConst.dealMarket)
        ?.filter((el: any) => el.dependences === undefined);
    }
  };

  const coverageTypeOption = () => {
    const isBasedMandateType =
      props.selectedMandate?.mandate_type === 'financing' ||
      props.selectedMandate?.mandate_type === 'hedge';
    if (isBasedMandateType) {
      // const isMultiple = el?.dependences?.split(',');
      return terms.getConst(TermConst.dealType)?.filter((el: any) => {
        const isMultiple = el?.dependences?.split(',');
        const found = isMultiple?.find(
          (t: any) => t === props.selectedMandate?.mandate_type,
        );
        return found;
        // return el.dependences === props.selectedMandate?.mandate_type;
      });
    } else {
      return terms.getConst(TermConst.dealType)?.filter((el: any) => {
        if (el && el.dependences) {
          const isMultiple = el?.dependences?.split(',');
          const found = isMultiple?.find((t: any) => t === market);
          if (found) {
            return el;
          }
        }
      });
    }
  };

  const riskTrigeerOption = () => {
    const marketFilters = [
      'trade_credit',
      'hybrid',
      'surety',
      'whole_turnover',
    ];

    if (marketFilters.includes(market)) {
      return terms.getConst(TermConst.dealRiskTrigger)?.filter((el: any) => {
        if (el && el.dependences) {
          const isMultiple = el?.dependences?.split(',');
          const found = isMultiple?.find((t: any) => t === market);
          if (found) {
            return el;
          }
        }
      });
    }
    return terms?.getConst(TermConst.dealRiskTrigger);
  };

  const getCountryName = (key: string) => {
    const country = responseCountry?.country.find((el) => el.key === key);
    if (country) {
      return country.name;
    }
    return '';
  };

  const getUnderlyingTransac = (key: string) => {
    if (terms?.getConst(TermConst.projectType)) {
      const found = terms
        .getConst(TermConst.projectType)
        .find((el: any) => el.key === key);
      if (found) {
        return found?.value || '';
      }
    }
  };

  const optionUserNameOwner = ownerList?.user.map((el: any) => {
    return {
      ...el,
      userName: concatString(el.lastname, el.firstname),
    };
  });

  const showErrorOutcome = () => {
    props.setFormError('outcome', {
      message: 'Outcome is required',
    });
  };

  const [deleteAttachment] = useDeleteEnquiryAttachmentMutation();

  const handleDeleteAtt = (item: EntityAttachementEnquiry) => {
    setSelectedAttachment(item);
    setModalDeleteAttach(true);
  };

  const onDeleteAttachment = () => {
    deleteAttachment({ id: selectedAttachment?.id ?? '' }).finally(() => {
      setModalDeleteAttach(false);
    });
  };

  const columnsProvider = ColumnUpload({ handleDeleteAtt: handleDeleteAtt });

  const handleLabelType = (mandate: EntityMandate) => {
    return mandate.mandate_type === 'insurance' ? 'Coverage Type' : 'Type';
  };

  const showErrorExposureCurrency = () => {
    props.setFormError('exposure_currency', {
      message: 'Currency is required',
    });
  };

  const showErrorIndividualCurrency = () => {
    props.setFormError('individual_currency', {
      message: 'Currency is required',
    });
  };

  return (
    <Container className="mt-4">
      <DeleteModal
        title="Delete attachments"
        show={modalDeleteAttach}
        groupeName={`${selectedAttachment?.filename ?? ''}`}
        entityTitle={'attachments'}
        validDelete={() => onDeleteAttachment()}
        handleClose={() => setModalDeleteAttach(false)}
      />
      <Row>
        <Col>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown ">
                Mandate ID <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  modalId="enquiries"
                  id="enquiries"
                  error={props.errors?.mandate_id?.message}
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {mandate_id ? (
                        <MandateRoundedActif
                          onClick={() =>
                            handleShowOtherModal(mandate_id, 'mandate')
                          }
                        />
                      ) : (
                        <MandateRoundedInactif />
                      )}
                    </span>
                  }
                  preValue={exportedDropdownValue(
                    makeOptions(props.mandateListe, 'complete_number', 'id'),
                    mandate_id,
                  )}
                  placeholder=""
                  options={makeOptions(
                    props.mandateListe,
                    'complete_number',
                    'id',
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'mandate_id',
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('mandate_id');
                      return;
                    }
                    props.setFormError('mandate_id', {
                      message: 'Mandate ID is required',
                    });
                  }}
                  onSearch={(d, v) => props.runSearch('mandate_id', v)}
                  {...props.register('mandate_id', {
                    required: true,
                  })}
                  onBlur={() => {
                    if (!isExist(mandate_id)) {
                      props.setFormError('mandate_id', {
                        message: 'Mandate ID is required',
                      });
                    }
                  }}
                  loading={props.isMandateLoading}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-3">
              <Form.Label className="txt">Mandate Name </Form.Label>

              <div className="d-flex flex-column w-100">
                {props.selectedMandate?.name || ''}
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-3">
              <Form.Label className="txt">Client ID</Form.Label>
              <div className="d-flex flex-column w-100">
                {props.selectedMandate?.client?.name || ''}
              </div>
              <span
                style={{
                  paddingInline: '8px',
                }}
              >
                {isExist(props.selectedMandate?.client?.name) ? (
                  <span style={{ cursor: 'pointer' }}>
                    <ClientActive
                      onClick={() =>
                        handleShowOtherModal(null, 'legal_entity_client_id')
                      }
                    />
                  </span>
                ) : (
                  <ClientInactif />
                )}
              </span>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Client Entity <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  error={props.errors?.client_legal_entity_id?.message}
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {client_legal_entity_id ? (
                        <LegalentitiesActif
                          onClick={() =>
                            handleShowOtherModal(
                              client_legal_entity_id,
                              'legal_entity',
                            )
                          }
                        />
                      ) : (
                        <LegalentitiesInactif />
                      )}
                    </span>
                  }
                  noSearch
                  modalId="enquiries-client"
                  id="client_legal_entity_id"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(props.legalEntityList || [], 'name', 'id'),
                    client_legal_entity_id,
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'client_legal_entity_id',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    );
                    if (e) {
                      props.clearFormErrors('client_legal_entity_id');
                      return;
                    }
                    props.setFormError('client_legal_entity_id', {
                      message: 'Client Entity is required',
                    });
                  }}
                  options={makeOptions(props.legalEntityList, 'name', 'id')}
                  {...props.register('client_legal_entity_id', {
                    required: true,
                  })}
                  onBlur={() => {
                    if (!isExist(client_legal_entity_id)) {
                      props.setFormError('client_legal_entity_id', {
                        message: 'Client Entity is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Enquiry Market <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noSearch
                  error={props.errors?.market?.message}
                  modalId="enquiries-client"
                  preValue={exportedDropdownValue(enquiriesOptions(), market)}
                  id="market"
                  placeholder=""
                  onSelectOption={(e) => {
                    props.setValue(
                      'market',
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('market');

                      return;
                    }
                    props.setFormError('market', {
                      message: 'Enquiry Market is required',
                    });
                  }}
                  options={enquiriesOptions()}
                  {...props.register('market', {
                    required: true,
                  })}
                  onBlur={() => {
                    if (!isExist(market)) {
                      props.setFormError('market', {
                        message: 'Enquiry Market is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline w-100`}>
              <Form.Label className="txt-with-dropdown">
                {handleLabelType(
                  (props.mandateListe?.find(
                    (el: EntityMandate) => el?.id === mandate_id,
                  ) as EntityMandate) || [],
                ) || 'Enquiry Type'}
                <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  disabled={!market}
                  noSearch
                  error={props.errors?.enquiry_type?.message}
                  modalId="enquiries-enquiry_type"
                  id="enquiry_type"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    coverageTypeOption(),
                    enquiry_type,
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'enquiry_type',
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('enquiry_type');
                      return;
                    }
                    props.setFormError('enquiry_type', {
                      message: 'Coverage Type is required',
                    });
                  }}
                  options={coverageTypeOption()}
                  {...props.register('enquiry_type', {
                    required: true,
                  })}
                  onBlur={() => {
                    if (!isExist(enquiry_type)) {
                      props.setFormError('enquiry_type', {
                        message: 'Coverage Type is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Risk Trigger
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noSearch
                  modalId="enquiry-risk_trigger"
                  id="enquiry-risk_trigger"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    riskTrigeerOption(),
                    risk_trigger,
                  )}
                  onSelectOption={(e) =>
                    props.setValue(
                      'risk_trigger',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    )
                  }
                  options={riskTrigeerOption()}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-3">
              <Form.Label className="txt">Top Obligor/Country</Form.Label>

              <div className="d-flex flex-column w-100">
                {props?.selectedMandateById?.entity_obligor?.name || ''}
              </div>
              <span style={{ cursor: 'pointer' }}>
                {isExist(props?.selectedMandateById?.entity_obligor?.name) ? (
                  <ObligorActif
                    onClick={() =>
                      handleShowOtherModal(null, 'top_obligor_entity')
                    }
                  />
                ) : (
                  <ObligorInActif />
                )}
              </span>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-3">
              <Form.Label className="txt">Country</Form.Label>

              <div className="d-flex flex-column w-100">
                {getCountryName(
                  props?.selectedMandateById?.entity_obligor?.country_id || '',
                )}
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 pt-3">
              <Form.Label className="txt">Underlying Transac.</Form.Label>

              <div className="d-flex flex-column w-100">
                {getUnderlyingTransac(
                  props?.selectedMandateById?.project_type || '',
                )}
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt txt-center-fix">
                Gross Exposure
              </Form.Label>
              <div className="d-flex flex-row  justify-content-center  w-100">
                <div className="w-100">
                  <CofarcoFormNumber
                    register={props.register}
                    setValue={props.setValue}
                    watch={props.watch}
                    key_form="exposure_gross"
                    acceptFloat={false}
                  />
                </div>
                <div className={`w-70 px-4`}>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-currency my-auto">
                      Currency
                    </Form.Label>{' '}
                    <SelectDropDown
                      noPadding
                      noSearch
                      notDeletable
                      error={props.errors.exposure_currency?.message}
                      disabled={exposure_gross < 1}
                      modalId="enquiry-exposure_currency"
                      id="enquiry-exposure_currency"
                      placeholder=""
                      preValue={exportedDropdownValue(
                        terms?.getConst(TermConst.currency),
                        exposure_currency,
                      )}
                      onSelectOption={(e) => {
                        props.setValue(
                          'exposure_currency',
                          (e as DropDownOptionObjectType).key,
                          { shouldDirty: true },
                        );
                        if (e) {
                          props.clearFormErrors('exposure_currency');
                          return;
                        }
                        showErrorExposureCurrency();
                      }}
                      options={terms?.getConst(TermConst.currency)}
                      onBlur={() => {
                        if (!isExist(exposure_currency)) {
                          showErrorExposureCurrency();
                        }
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline w-100 `}>
              <Form.Label className="txt txt-center-fix">
                Limit Required
              </Form.Label>

              <div className="d-flex flex-row  justify-content-center  w-100">
                <div className="w-100">
                  <CofarcoFormNumber
                    register={props.register}
                    setValue={props.setValue}
                    watch={props.watch}
                    key_form="individual_limits"
                    acceptFloat={false}
                  />
                </div>
                <div className={`w-70 px-4 `}>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-currency my-auto">
                      Currency
                    </Form.Label>{' '}
                    <SelectDropDown
                      noPadding
                      noSearch
                      error={props.errors.individual_currency?.message}
                      notDeletable
                      disabled={individual_limits < 1}
                      modalId="enqury-individual_currency"
                      id="enqury-individual_currency"
                      placeholder=""
                      preValue={exportedDropdownValue(
                        terms?.getConst(TermConst.currency),
                        individual_currency,
                      )}
                      onSelectOption={(e) => {
                        props.setValue(
                          'individual_currency',
                          (e as DropDownOptionObjectType).key,
                          {
                            shouldDirty: true,
                          },
                        );
                        if (e) {
                          props.clearFormErrors('individual_currency');
                          return;
                        }
                        showErrorIndividualCurrency();
                      }}
                      onBlur={() => {
                        if (!isExist(individual_currency)) {
                          showErrorIndividualCurrency();
                        }
                      }}
                      options={terms?.getConst(TermConst.currency)}
                    />
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt txt-center-fix">Tenor</Form.Label>

              <div className="d-flex flex-row  justify-content-center  w-100">
                <div className="w-100">
                  <CofarcoFormNumber
                    register={props.register}
                    setValue={props.setValue}
                    watch={props.watch}
                    key_form="protection"
                    acceptFloat={false}
                    isInteger={true}
                  />
                </div>
                <div className={`w-70 px-4 `}>
                  <Form.Group className={`d-flex align-items-baseline`}>
                    <Form.Label className="txt-currency"></Form.Label>
                    <SelectDropDown
                      noPadding
                      disabled={protection < 1}
                      modalId="mandate-client"
                      id="enquiry-protection_unit"
                      noSearch
                      placeholder=""
                      preValue={exportedDropdownValue(
                        mockTenor,
                        protection_unit,
                      )}
                      onSelectOption={(e) =>
                        props.setValue(
                          'protection_unit',
                          (e as DropDownOptionObjectType).key,
                          { shouldDirty: true },
                        )
                      }
                      options={mockTenor}
                    />
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt txt-center-fix">
                Insured Percentage
              </Form.Label>
              <div className="d-flex flex-row  justify-content-center  w-100">
                <div className="w-100">
                  <CofarcoNumberPercentage
                    errors={props.errors}
                    key_form="insured_percent"
                    type="number"
                    class="w-100"
                    register={props.register}
                    setValue={props.setValue}
                    watch={props.watch}
                  />
                </div>
              </div>
            </Form.Group>
          </RowContent>
        </Col>
        <Col>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100 mt-2">
              <Form.Label className="txt txt-center-fix">Deductible</Form.Label>
              <div className="w-100">
                <CofarcoFormNumber
                  register={props.register}
                  setValue={props.setValue}
                  watch={props.watch}
                  key_form="deductible"
                  acceptFloat={false}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt">Proposed Pricing</Form.Label>
              <Form.Control
                defaultValue={''}
                {...props.register('proposed_pricing')}
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Status<span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  noSearch
                  modalId="status"
                  id="status"
                  placeholder=""
                  error={props.errors?.status?.message}
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.enquiryStatus),
                    status,
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'status',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    );
                    props.clearFormErrors('status');
                    //remove error outcome when status change
                    props.clearFormErrors('outcome');
                  }}
                  options={terms?.getConst(TermConst.enquiryStatus)}
                  {...props.register('status', {
                    required: true,
                  })}
                  onBlur={() => {
                    if (!isExist(status)) {
                      props.setFormError('status', {
                        message: 'Status is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Outcome{' '}
                {status === 'closed' && (
                  <span className="span-required">*</span>
                )}
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  noSearch
                  modalId="mandate-client"
                  id="client_id_mandate"
                  placeholder=""
                  disabled={status !== 'closed'}
                  error={props.errors?.outcome?.message}
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.enquiryOutcome),
                    outcome,
                  )}
                  onSelectOption={(e) => {
                    const isRequiredError =
                      status === 'closed' &&
                      !isExist((e as DropDownOptionObjectType).key);
                    props.setValue(
                      'outcome',
                      (e as DropDownOptionObjectType)?.key,
                      {
                        shouldDirty: true,
                      },
                    );
                    if (isRequiredError) {
                      showErrorOutcome();
                      return;
                    }

                    props.clearFormErrors('outcome');
                  }}
                  options={terms?.getConst(TermConst.enquiryOutcome)}
                  onBlur={() => {
                    const isRequiredError =
                      status === 'closed' && !isExist(outcome);
                    if (isRequiredError) {
                      showErrorOutcome();
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt">
                Start Date <span className="span-required">*</span>
              </Form.Label>

              <CofarcoCalendar
                control={props.control}
                name="start_date"
                clearFormErrors={props.clearFormErrors}
                setFormError={props.setFormError}
                setFormValue={props.setValue}
                watchFormValue={props.watch}
                errors={props.errors}
                required
                txtErrorMsg="Start Date is required"
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt">End Date</Form.Label>
              <CofarcoCalendar
                control={props.control}
                name="end_date"
                setFormValue={props.setValue}
                watchFormValue={props.watch}
                clearFormErrors={props.clearFormErrors}
                errors={props.errors}
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Enquiry Lead <span className="span-required">*</span>
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  error={props.errors?.lead_user_id?.message}
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {lead_user_id ? (
                        <TeamActif
                          onClick={() =>
                            handleShowOtherModal(lead_user_id, 'cofarco_team')
                          }
                        />
                      ) : (
                        <TeamInactif />
                      )}
                    </span>
                  }
                  modalId="mandate-client"
                  id="enquiries-lead_user_id"
                  placeholder=""
                  noSearch
                  preValue={exportedDropdownValue(
                    makeOptions(optionUserNameOwner || [], 'userName', 'id'),
                    lead_user_id,
                  )}
                  options={makeOptions(
                    optionUserNameOwner || [],
                    'userName',
                    'id',
                  )}
                  onSelectOption={(e) => {
                    props.setValue(
                      'lead_user_id',
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      },
                    );
                    if (e) {
                      props.clearFormErrors('lead_user_id');
                      return;
                    }
                    props.setFormError('lead_user_id', {
                      message: 'Enquiry Lead is required',
                    });
                  }}
                  {...props.register('lead_user_id', {
                    required: true,
                  })}
                  onBlur={() => {
                    if (!isExist(lead_user_id)) {
                      props.setFormError('lead_user_id', {
                        message: 'Enquiry Lead is required',
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Enquiry Support 1
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {support1_user_id ? (
                        <TeamActif
                          onClick={() =>
                            handleShowOtherModal(
                              support1_user_id,
                              'cofarco_team',
                            )
                          }
                        />
                      ) : (
                        <TeamInactif />
                      )}
                    </span>
                  }
                  noSearch
                  modalId="mandate-client"
                  id="enquiries-support1_user_id"
                  options={makeOptions(
                    optionUserNameOwner || [],
                    'userName',
                    'id',
                  )}
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(optionUserNameOwner || [], 'userName', 'id'),
                    support1_user_id,
                  )}
                  onSelectOption={(e) =>
                    props.setValue(
                      'support1_user_id',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    )
                  }
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">
                Enquiry Support 2
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {support2_user_id ? (
                        <TeamActif
                          onClick={() =>
                            handleShowOtherModal(
                              support2_user_id,
                              'cofarco_team',
                            )
                          }
                        />
                      ) : (
                        <TeamInactif />
                      )}
                    </span>
                  }
                  noSearch
                  modalId="enquiry-support2_user_id"
                  id="enquiry-support2_user_id"
                  options={makeOptions(
                    optionUserNameOwner || [],
                    'userName',
                    'id',
                  )}
                  preValue={exportedDropdownValue(
                    makeOptions(optionUserNameOwner || [], 'userName', 'id'),
                    support2_user_id,
                  )}
                  placeholder=""
                  onSelectOption={(e) =>
                    props.setValue(
                      'support2_user_id',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    )
                  }
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">Partner</Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {partner_id ? (
                        <PartnerActif
                          onClick={() =>
                            handleShowOtherModal(partner_id, 'partner')
                          }
                        />
                      ) : (
                        <PartnerIncatif />
                      )}
                    </span>
                  }
                  modalId="enquiries-partner_id"
                  id="partner_id_mandate"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(props.partenerList || [], 'name', 'id'),
                    partner_id,
                  )}
                  onSelectOption={(e) =>
                    props.setValue(
                      'partner_id',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    )
                  }
                  options={makeOptions(props.partenerList || [], 'name', 'id')}
                  onSearch={(d, v) => props.runSearch('partner_id', v)}
                  {...props.register('partner_id')}
                  loading={props.isPartenerLoading}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex align-items-baseline w-100">
              <Form.Label className="txt-with-dropdown">Contact</Form.Label>

              <div className={`d-flex flex-column w-100`}>
                <SelectDropDown
                  noPadding
                  icon={
                    <span style={{ cursor: 'pointer' }}>
                      {partner_contact_id ? (
                        <PeopleActif
                          onClick={() =>
                            handleShowOtherModal(partner_contact_id, 'people')
                          }
                        />
                      ) : (
                        <PeopleIncatif />
                      )}
                    </span>
                  }
                  disabled={!partner_id}
                  modalId="mandate-client"
                  noSearch
                  id="enquiries_partner_contact_id"
                  placeholder=""
                  preValue={exportedDropdownValue(
                    makeOptions(props.partenersPeople, 'userName', 'id'),
                    partner_contact_id,
                  )}
                  onSelectOption={(e) =>
                    props.setValue(
                      'partner_contact_id',
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true },
                    )
                  }
                  options={makeOptions(props.partenersPeople, 'userName', 'id')}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent height="auto">
            <Form.Group className="d-flex  align-items-start mt-2">
              <Form.Label className="label-text-area">Comments</Form.Label>
              <div className="d-flex flex-column w-100 mb-3">
                <Form.Control
                  as="textarea"
                  rows={5}
                  className=" w-100"
                  {...props.register('comments')}
                />
              </div>
            </Form.Group>
          </RowContent>
        </Col>
      </Row>
      {id ? (
        <>
          <div style={{ height: '1px', backgroundColor: '#DBDDDF' }} />
          <Row>
            <>
              <div className="content-table mt-3">
                <div className="font-bold mb-3">Attachment(s)</div>
                <div className="content-table">
                  <div
                    style={{
                      maxWidth: '100%',
                      overflowX: 'scroll',
                      overflowY: 'clip',
                    }}
                  >
                    <Table
                      dataSource={provider.attachments?.attachment ?? []}
                      columns={columnsProvider.columnAttachments}
                      loading={provider.isLoadingAttachement}
                      pagination={false}
                    />
                  </div>
                </div>
                {!props.hiddenFooter && (
                  <div className="d-flex">
                    <Button
                      className="btn red mt-3 py-2"
                      onClick={() => {
                        document.getElementById('attachmentDeals')?.click();
                      }}
                    >
                      <RiFolderUploadFill className="me-2" />
                      <span className="ms-1">Upload</span>
                    </Button>
                    <input
                      className="d-none"
                      type="file"
                      id="attachmentDeals"
                      onChange={(e) => {
                        props.onAddAttachment(e.target.files);
                        e.target.files = null;
                        e.target.value = '';
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          </Row>
        </>
      ) : (
        <></>
      )}

      <ModalUnsavedChange
        dirtiFields={provider.dirtyFields}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          provider.handleLeaveModalWithoutSave(
            showOtherModal?.id,
            showOtherModal?.func,
            showOtherModal?.type,
          )
        }
        showModalUnsaved={provider.isShowModalUnsavedChange}
      />
    </Container>
  );
}
