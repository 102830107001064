import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { isCanReadMenu } from '../utils/helper-function';
import { Menu } from '../@types/common-types';
import { useSeenMutation } from '../redux/api/ws/dashboard/dashboard';
import WrapperPermission from '../components/WrapperPermission/WrapperPermission';
import Loader from '../components/Loader/Loader';
import AdminBase from '../components/Admin/AdminBase';
import AuthRequired from '../components/AuthRequired/AuthRequired';
import Dashboard from '../pages/Dashboard/Dashboard';

const Login = lazy(() => import('../pages/login/Login'));

const Groupe = lazy(() => import('../pages/Database/Groupe'));

const CofarcoTeams = lazy(() => import('../pages/CofarcoTeams/CofarcoTeams'));

const People = lazy(() => import('../pages/Database/People'));

const LegalEntity = lazy(() => import('../pages/Database/LegalEntity'));

const NotFound = lazy(() => import('../pages/NotFound'));

const DatabaseContent = lazy(
  () => import('../components/Database/DatabaseContent'),
);

const CounterPartyContent = lazy(
  () => import('../components/CounterParties/CounterPartiesContent'),
);

const Client = lazy(() => import('../pages/CounterParties/Client'));

const Financier = lazy(() => import('../pages/CounterParties/Financier'));

const SegregatedContent = lazy(
  () => import('../components/SegregatedAccounts/SegregatedContent'),
);

const Notes = lazy(() => import('../pages/SegregatedAccounts/Notes'));

const Payements = lazy(() => import('../pages/SegregatedAccounts/Payements'));

const MandatesContent = lazy(
  () => import('../components/Mandates/MandatesContent'),
);

const Mandates = lazy(() => import('../pages/Mandates/Mandates'));

const Underwriter = lazy(() => import('../pages/CounterParties/Underwriter'));

const EnquiriesContent = lazy(
  () => import('../components/Enquiries/EnquiriesContent'),
);

const Enquiries = lazy(() => import('../pages/Enquiries/Enquiries'));

const DealsContent = lazy(() => import('../components/Deals/DealsContent'));

const Deals = lazy(() => import('../pages/Deals/Deals'));

const Partner = lazy(() => import('../pages/CounterParties/Partner'));

const Obligor = lazy(() => import('../pages/CounterParties/Obligor'));

const Indication = lazy(() => import('../pages/Indication/Indication'));

const PipeContent = lazy(() => import('../components/Pipe/PipeContent'));

const Pipe = lazy(() => import('../pages/Pipe/Pipe'));

const ParticipationContent = lazy(
  () => import('../components/Participations/ParticipationContent'),
);

const Participations = lazy(
  () => import('../pages/Participations/Participations'),
);

const History = lazy(() => import('../pages/History/History'));

const HistoryContent = lazy(
  () => import('../components/History/HistoryContent'),
);

const StaffContent = lazy(
  () => import('../components/CofarcoTeams/StaffContent'),
);

export const AppRouter = () => {
  const menu = [
    {
      url: '/database/people',
      isAccepted: isCanReadMenu(Menu.people),
    },
    {
      url: '/database/legal-entity',
      isAccepted: isCanReadMenu(Menu.legal_entity),
    },
    {
      url: '/database/group',
      isAccepted: isCanReadMenu(Menu.group),
    },
    {
      url: '/database/staff',
      isAccepted: isCanReadMenu(Menu.user),
    },
  ];

  const menuCounterparty = [
    {
      url: '/counterparty/client',
      isAccepted: isCanReadMenu(Menu.client),
    },
    {
      url: '/counterparty/financier',
      isAccepted: isCanReadMenu(Menu.financier),
    },
    {
      url: '/counterparty/underwriter',
      isAccepted: isCanReadMenu(Menu.underwriter),
    },
    {
      url: '/counterparty/partner',
      isAccepted: isCanReadMenu(Menu.partner),
    },
    {
      url: '/counterparty/obligor',
      isAccepted: isCanReadMenu(Menu.obligor),
    },
  ];

  const menuSegregated = [
    {
      url: '/segregated-accounts/notes',
      isAccepted: isCanReadMenu(Menu.note),
    },
    {
      url: '/segregated-accounts/payements',
      isAccepted: isCanReadMenu(Menu.payment),
    },
  ];

  const menuDashboard = [
    {
      url: '/dashboard',
      isAccepted: isCanReadMenu(Menu.dashboard),
    },
  ];

  const defaultUrl = menu.find((el) => el.isAccepted === true);

  const defaultUrlCounterParty = menuCounterparty.find(
    (el) => el.isAccepted === true,
  );

  const defaultUrlSegregated = menuSegregated.find(
    (el) => el.isAccepted === true,
  );

  const defaultUrlDashboard = menuDashboard.find(
    (el) => el.isAccepted === true,
  );

  // Handle location leave
  const pathname = useLocation().pathname;
  const [previousLocation, setPreviousLocation] = useState<
    string | null | undefined
  >(null);

  const [setDashboardSeen] = useSeenMutation();

  useEffect(() => {
    // User leaves dashboard, if previous route is dashboard
    if (previousLocation?.includes('dashboard')) {
      setDashboardSeen({}).catch((err: any) => {
        console.log('setDashboardSeen', err);
      });
    }
    setPreviousLocation(pathname);
  }, [pathname]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRequired>
              <AdminBase />
            </AuthRequired>
          }
        >
          <Route index element={
            <WrapperPermission permission={Menu.dashboard}>
              <Dashboard />
            </WrapperPermission>
          } />
          <Route
            path="dashboard"
            element={
              <WrapperPermission permission={Menu.dashboard}>
                <Dashboard />
              </WrapperPermission>
            }
          ></Route>
          <Route
            index
            element={<Navigate to={defaultUrlDashboard?.url || '/pipe/list'} />}
          />
          <Route
            path="indication"
            element={
              <WrapperPermission permission={Menu.indication}>
                <Indication />
              </WrapperPermission>
            }
          ></Route>
          <Route path="database" element={<DatabaseContent />}>
            <Route
              index
              element={<Navigate to={defaultUrl?.url || '/database/people'} />}
            />
            <Route
              index
              path="people"
              element={
                <WrapperPermission permission={Menu.people}>
                  <People />
                </WrapperPermission>
              }
            />
            <Route
              path="group"
              element={
                <WrapperPermission permission={Menu.group}>
                  <Groupe />
                </WrapperPermission>
              }
            />
            <Route
              path="legal-entity"
              element={
                <WrapperPermission permission={Menu.legal_entity}>
                  <LegalEntity />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="counterparty" element={<CounterPartyContent />}>
            <Route
              index
              element={
                <Navigate
                  to={defaultUrlCounterParty?.url || '/counterparty/client'}
                />
              }
            />
            <Route
              path="client"
              element={
                <WrapperPermission permission={Menu.client}>
                  <Client />
                </WrapperPermission>
              }
            />
            <Route
              path="financier"
              element={
                <WrapperPermission permission={Menu.financier}>
                  <Financier />
                </WrapperPermission>
              }
            />
            <Route
              path="underwriter"
              element={
                <WrapperPermission permission={Menu.underwriter}>
                  <Underwriter />
                </WrapperPermission>
              }
            />
            <Route
              path="partner"
              element={
                <WrapperPermission permission={Menu.partner}>
                  <Partner />
                </WrapperPermission>
              }
            />
            <Route
              path="obligor"
              element={
                <WrapperPermission permission={Menu.obligor}>
                  <Obligor />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="segregated-accounts" element={<SegregatedContent />}>
            <Route
              index
              element={
                <Navigate
                  to={defaultUrlSegregated?.url || '/segregated-accounts/notes'}
                />
              }
            />
            <Route
              path="notes"
              element={
                <WrapperPermission permission={Menu.note}>
                  <Notes />
                </WrapperPermission>
              }
            />
            <Route
              path="payments"
              element={
                <WrapperPermission permission={Menu.payment}>
                  <Payements />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="mandates" element={<MandatesContent />}>
            <Route index element={<Navigate to="/mandates/liste" />} />
            <Route
              path="liste"
              element={
                <WrapperPermission permission={Menu.mandate}>
                  <Mandates />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="enquiries" element={<EnquiriesContent />}>
            <Route index element={<Navigate to="/enquiries/liste" />} />
            <Route
              path="liste"
              element={
                <WrapperPermission permission={Menu.enquiry}>
                  <Enquiries />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="deals" element={<DealsContent />}>
            <Route index element={<Navigate to="/deals/liste" />} />
            <Route
              path="liste"
              element={
                <WrapperPermission permission={Menu.deal}>
                  <Deals />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="pipe" element={<PipeContent />}>
            <Route index element={<Navigate to="/pipe/liste" />} />
            <Route
              path="liste"
              element={
                <WrapperPermission permission={Menu.pipe}>
                  <Pipe />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="participations" element={<ParticipationContent />}>
            <Route index element={<Navigate to="/participations/liste" />} />
            <Route
              path="liste"
              element={
                <WrapperPermission permission={Menu.participation}>
                  <Participations />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="history" element={<HistoryContent />}>
            <Route index element={<Navigate to="/history/liste" />} />
            <Route
              path="liste"
              element={
                <WrapperPermission permission={Menu.history}>
                  <History />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="cofarco-team" element={<StaffContent />}>
            <Route index element={<Navigate to="/cofarco-team/staff" />} />
            <Route
              index
              path="staff"
              element={
                <WrapperPermission permission={Menu.user}>
                  <CofarcoTeams />
                </WrapperPermission>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </SearchContext.Provider> */}
    </Suspense>
  );
};
