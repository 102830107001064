import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slice/authSlice';

import Logo from '../../assets/img/Logo.svg';
import logoSmall from '../../assets/img/logo_small.svg';
import segregated from '../../assets/img/Segregated.svg';
import indications from '../../assets/img/Indications.svg';
import { baseApi } from '../../redux/api/baseApi';
import { RootState } from '../../redux/store';
import { isHaveAllAccessMenu } from '../../utils/helper-function';
import { PermissionType, User } from '../../@types/common-types';
import { closeAllModal } from '../../redux/slice/modalSlice';
import bugReporting from '../../assets/img/setting.svg';

type Props = {
  menu: boolean;
  setMenu: (menu: boolean) => any;
};

const SideNav = ({ menu, setMenu }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const rights = auth.user ? (auth.user as User).rights : {};

  const disconnect = () => {
    localStorage.removeItem('token');
    dispatch(logout());
    dispatch(baseApi.util.resetApiState());
    navigate('/login');
  };

  const closeModal = () => {
    dispatch(closeAllModal());
  };

  const activeStyle = {
    borderRight: 'solid 5px #EB5D57',
    backgroundColor: '#DBDDDF',
    borderRadius: '8px 0px 0px 8px',
  };

  const permissionDb: Array<PermissionType> = [
    rights.group,
    rights.legal_entity,
    rights.people,
  ];

  const permissionCounterPartie: Array<PermissionType> = [
    rights.client,
    rights.financier,
    rights.underwriter,
    rights.partner,
    rights.obligor,
  ];

  const permissionSegregated: Array<PermissionType> = [
    rights.note,
    rights.payment,
  ];

  const permissionIndication: Array<PermissionType> = [rights.indication];
  const permissionParticipation: Array<PermissionType> = [rights.participation];

  const permissionEnquiries: Array<PermissionType> = [rights.enquiry];

  const permissionHistory: Array<PermissionType> = [rights.history];

  const permissionDeals: Array<PermissionType> = [rights.deal];

  const permissionMandate: Array<PermissionType> = [rights.mandate];

  const permissionPipe: Array<PermissionType> = [rights.pipe];

  const permissionDashboard: Array<PermissionType> = [rights.dashboard];

  const permissionCofarcoTeam: Array<PermissionType> = [rights.user];

  return (
    <div className={`leftSide ${!menu ? 'no-txt-menu' : ''} `} style={{}}>
      <div className="logo">
        <img
          src={Logo}
          alt="Logo Cofar"
          className="logoHeader"
          style={{ cursor: 'pointer' }}
          onClick={closeModal}
        />
        <img
          src={logoSmall}
          alt="Logo Cofar"
          className="smallLogoHeader"
          style={{ cursor: 'pointer' }}
          onClick={closeModal}
        />
      </div>
      <div className="menu">
        <ul>
          {isHaveAllAccessMenu(permissionDashboard) && (
            <li>
              <NavLink
                to="/dashboard"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Dashboard"></span>
                <span className="txt-menu">Dashboard</span>
              </NavLink>
            </li>
          )}
          {isHaveAllAccessMenu(permissionPipe) && (
            <li>
              <NavLink
                to="/pipe"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Pipe"></span>
                <span className="txt-menu">Pipe</span>
              </NavLink>
            </li>
          )}
          {isHaveAllAccessMenu(permissionMandate) && (
            <li>
              <NavLink
                to="/mandates"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span
                  className="icon-Vector-Mandate"
                  style={{ fontSize: '18px' }}
                ></span>
                <span className="txt-menu">Mandates</span>
              </NavLink>
            </li>
          )}

          {isHaveAllAccessMenu(permissionDeals) && (
            <li>
              <NavLink
                to="/deals"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Deals"></span>
                <span className="txt-menu">Deals</span>
              </NavLink>
            </li>
          )}
          {isHaveAllAccessMenu(permissionParticipation) && (
            <li>
              <NavLink
                to="/participations"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Participations"></span>
                <span className="txt-menu">Participations</span>
              </NavLink>
            </li>
          )}

          {isHaveAllAccessMenu(permissionEnquiries) && (
            <li>
              <NavLink
                to="/enquiries"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Enquiries"></span>
                <span className="txt-menu">Enquiries</span>
              </NavLink>
            </li>
          )}

          {isHaveAllAccessMenu(permissionIndication) && (
            <li>
              <NavLink
                to="/indication"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <img src={indications} alt="" />
                <span className="txt-menu">Indications</span>
              </NavLink>
            </li>
          )}

          {isHaveAllAccessMenu(permissionSegregated) && (
            <li>
              <NavLink
                to="/segregated-accounts"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <img src={segregated} alt="" />
                <span className="txt-menu">Segregated Accounts</span>
              </NavLink>
            </li>
          )}

          {isHaveAllAccessMenu(permissionCounterPartie) && (
            <li>
              <NavLink
                to="/counterparty"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Counterparties1"></span>
                <span className="txt-menu">Counterparties</span>
              </NavLink>
            </li>
          )}

          {isHaveAllAccessMenu(permissionDb) && (
            <li>
              <NavLink
                to="/database"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Data-base"></span>
                <span className="txt-menu">Data Base</span>
              </NavLink>
            </li>
          )}
          {isHaveAllAccessMenu(permissionCofarcoTeam) && (
            <li>
              <NavLink
                to="/cofarco-team"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-Team"></span>
                <span className="txt-menu">Cofarco Team</span>
              </NavLink>
            </li>
          )}
          {isHaveAllAccessMenu(permissionHistory) && (
            <li>
              <NavLink
                to="/history"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <span className="icon-History"></span>
                <span className="txt-menu">History</span>
              </NavLink>
            </li>
          )}
          <li className="lastItem">
            <a href="https://form.asana.com/?k=GnEFLPK87R5KFnek5ukqcQ&d=7932624731901" target='_blank'>
              <img
                src={bugReporting}
                alt="Bug Reporting"
                className="smallLogoHeader"
                style={{width: '24.75px', height: '22px'}}
              />
              <span className="txt-menu">Bug Reporting</span>
            </a>
          </li>
          <li className="logOut" onClick={() => disconnect()}>
            <NavLink to="#">
              <span className="icon-Log-out"></span>
              <span className="txt-menu">Log Out</span>
            </NavLink>
          </li>
        </ul>
        <NavLink
          to="#"
          className="arrow-menu"
          onClick={() => setMenu(!menu)}
        ></NavLink>
      </div>
    </div>
  );
};

export default SideNav;
