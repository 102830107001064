import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BusinessType, ConterParty } from '../../../@types/conterParty';
import { EntityEnquiries, InputFormEnquiries } from '../../../@types/enquiries';
import { LegalEntity } from '../../../@types/legal-entities';
import { EntityMandate } from '../../../@types/mandates/mandates';
import { People } from '../../../@types/people';
import { useAppDispatch } from '../../../hooks/redux';
import { useLazyGetCounterPartyByIdQuery } from '../../../redux/api/ws/counterparties/get';
import { useLazyCofarcoTeamByIdQuery } from '../../../redux/api/ws/databases/cofarco-teams';
import { useLazyLegalEntityByIdQuery } from '../../../redux/api/ws/databases/legale-entities';

import {
  useAddMarketMutation,
  useCreateEnquiriesMutation,
  useGetCounterPartyPartnerByIdMutation,
  useGetLegalEntityByConterPartIdMutation,
  useGetMandateByLegalEntityMutation,
  useLazyGetIndicationByIdQuery,
  useSearchMandateNameInEnquiriesMutation,
  useUpdateEnquiriesMutation,
  useUploadEnquiryAttachementMutation,
  useGetEnquiryAttachementQuery,
} from '../../../redux/api/ws/enquiries/enquiries';
import {
  useGetOwnerQuery,
  useGetPeopleByConterPartyIdMutation,
  useSearchCofarcoMutation,
} from '../../../redux/api/ws/mandates/mandates';
import { updateModalAction } from '../../../redux/slice/modalSlice';
import { updateNotificationMessage } from '../../../redux/slice/notificationSlice';
import {
  amountFormat,
  checkIsEdit,
  concatString,
  exportDateUi,
  exportDateWs,
  floorNumber,
  getObjectDifferences,
  isAcceptedFile,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
  setEmptyUndefinedEditValues,
} from '../../../utils/helper-function';
import { addPanelWs } from '../../../redux/api/axiosBaseQuery';
import { url } from '../../../redux/api/url';
import { baseApi } from '../../../redux/api/baseApi';
import { isEmpty } from 'lodash';
import { FunctionName } from '../../../@types/common-types';
type IProps = {
  defaultFormValues: InputFormEnquiries | null;
  closingModal: (itemsDataSource: EntityEnquiries | null) => void;
};
export default function UseModalEnquiries(props: IProps) {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCompletedForm, setIsCompletedForm] = useState(false);
  const [showPromptEnquiry, setShowPromptEnquiry] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isShowModalUnsavedChange, setIsShowModalUnsavedChange] =
    useState<boolean>(false);
  const [showOtherModal, setShowOtherModal] = useState<{
    id: any;
    func: string;
  }>();

  const [selectedMarket, setSelectedMarket] =
    React.useState<ConterParty | null>(null);

  const [selectedEnquiry, setSelectedEnquiry] =
    React.useState<EntityEnquiries | null>(null);

  const [getIndicationIdInPanel] = useLazyGetIndicationByIdQuery();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    watch,
    getValues,
    setError: setFormError,
    clearErrors: clearFormErrors,
    setValue,
    reset,
  } = useForm<InputFormEnquiries>({
    defaultValues: {
      exposure_gross: 0,
      individual_limits: 0,
      protection: 0,
      deductible: '0',
      insured_percent: '% 0.0',
    },
    reValidateMode: 'onChange',
  });
  const mandate_id = watch('mandate_id');
  const enquiryNumber = props.defaultFormValues?.number;
  const [getMandate, { isLoading: isGetMandateLoading }] =
    useSearchMandateNameInEnquiriesMutation();
  const [getPeopleByCounterPartie] = useGetPeopleByConterPartyIdMutation();
  const [getLegalEntity] = useGetLegalEntityByConterPartIdMutation();
  const [getPartner, { isLoading: isGetCounterLoading }] =
    useGetCounterPartyPartnerByIdMutation();
  const [getMandateByLegalEntity, { isLoading: isGetMandatBIdLoading }] =
    useGetMandateByLegalEntityMutation();
  const [getCofarco, { isLoading: isSearchCoLoading }] =
    useSearchCofarcoMutation();
  const { data: ownerList } = useGetOwnerQuery();
  const [createEnquiries] = useCreateEnquiriesMutation();
  const [updateEnquiries] = useUpdateEnquiriesMutation();
  const [getClientById] = useLazyGetCounterPartyByIdQuery();
  const [getLegalEntityById] = useLazyLegalEntityByIdQuery();
  const [
    addMarket,
    { isLoading: isLoadingCreateMarket, isSuccess: isSuccessCreateMarket },
  ] = useAddMarketMutation();

  const dispatch = useAppDispatch();
  const [partenerList, setPartenerList] = useState<Array<ConterParty>>([]);
  const [legalEntityList, setLegalEntityList] = useState<Array<LegalEntity>>(
    [],
  );
  const [partenersPeople, setPartenersPeople] = useState<Array<People>>([]);
  const [mandateListe, setMandateListe] = useState<Array<EntityMandate>>([]);

  const [getCofarcoTeam] = useLazyCofarcoTeamByIdQuery();

  const {
    data: attachments,
    isLoading: isLoadingGetEnquiryAttachement,
    isFetching: isFetchingGetEnquiryAttachement,
    refetch: refetchGetEnquiryAttachement,
  } = useGetEnquiryAttachementQuery({ id: props.defaultFormValues?.id || 0 });

  const showErrorExposureCurrency = () => {
    setFormError('exposure_currency', {
      message: 'Currency is required',
    });
  };

  const showErrorIndividualCurrency = () => {
    setFormError('individual_currency', {
      message: 'Currency is required',
    });
  };

  const [addAttachment, { isLoading: isLoadingAttachement }] =
    useUploadEnquiryAttachementMutation();

  const [selectedMandateById, setSelectedMandateById] =
    useState<EntityMandate | null>(null);

  const optionUserNameOwner = ownerList?.user.map((el: any) => {
    return {
      ...el,
      userName: concatString(el.lastname, el.firstname),
    };
  });

  const [selectedMandate, setSelectedMandate] = useState<
    EntityMandate | undefined
  >();

  useEffect(() => {
    setSelectedMandate(
      mandateListe?.find((el: EntityMandate) => el.id === mandate_id),
    );
  }, [mandateListe, mandate_id]);

  const modalTitle = isExist(props.defaultFormValues?.id)
    ? `Enquiry - ${props.defaultFormValues?.number} ${selectedMandate?.client.name ? ' - ' + selectedMandate?.client.name : ''} ${selectedMandate ? ' - ' + selectedMandate?.name : ''}`
    : ' New - Enquiry';

  const id = watch('id');

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormEnquiries>(watch());
    reset({
      ...defaultValue,
      exposure_gross: 0,
      individual_limits: 0,
      protection: 0,
      deductible: '0',
      insured_percent: '% 0.0',
    });
    setIsEdit(false);
    setSelectedMandateById(null);
    setMandateListe([]);
    setPartenerList([]);
    setLegalEntityList([]);
    setSelectedMandate(undefined);
    clearFormErrors();
  };

  const handleMandateId = async (text: string | undefined) => {
    try {
      const response = await getMandate({
        query: `${text?.trim()}`,
      }).unwrap();

      setMandateListe(response?.mandate);
    } catch (error) {
      return [];
    }
  };

  const handlCounterPartie = async (text: string, type: BusinessType) => {
    try {
      const response = await getCofarco({
        page: 1,
        start: 0,
        limit: 100,
        query: `%${text?.trim()}%`,
        businessType: type,
      }).unwrap();

      const tempOption = response?.counterparty;
      if (type === 'partner') {
        setPartenerList(tempOption);
      }
    } catch (error) {
      return [];
    }
  };

  const handleLegalEntity = async (id: number) => {
    try {
      const resp = await getLegalEntity({ id }).unwrap();
      setLegalEntityList(resp.legalentity);
    } catch (error) {
      return [];
    }
  };

  const handleMandateByLegalEntity = async (id: number) => {
    try {
      const resp = await getMandateByLegalEntity({ id }).unwrap();
      setSelectedMandateById(resp.mandate);
      if (resp?.mandate?.in_charge_user_id && !watch('lead_user_id')) {
        setValue('lead_user_id', resp?.mandate?.in_charge_user_id);
      }
      //is Edit
      if (props.defaultFormValues) {
        setMandateListe([resp.mandate]);
        handleLegalEntity(resp.mandate?.client?.id);
      }
    } catch (error) {
      return [];
    }
  };

  const getPeople = async (id: number | string) => {
    if (id) {
      try {
        const resp = await getPeopleByCounterPartie({ id }).unwrap();
        const floorData =
          resp.people?.map((el: any) => ({
            ...el,
            userName: concatString(el.lastname, el.firstname),
          })) || [];
        setPartenersPeople(floorData);
      } catch (error) {
        setPartenersPeople([]);
      }
    }
  };

  const handlePartnerCounterParty = async (id: number) => {
    try {
      const resp = await getPartner({ id }).unwrap();
      setPartenerList([resp.counterparty]);
    } catch (error) {
      return [];
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'mandate_id') {
        if (value.mandate_id) {
          const selectedMandate: EntityMandate | undefined = mandateListe?.find(
            (el: EntityMandate) => el.id === value.mandate_id,
          );
          if (selectedMandate?.client.id) {
            handleLegalEntity(selectedMandate?.client.id);
          }
          if (selectedMandate) {
            handleMandateByLegalEntity(selectedMandate.id);
          }
        } else {
          setLegalEntityList([]);
          setValue('client_legal_entity_id', null);
          setSelectedMandateById(null);
          setValue('lead_user_id', null);
        }
      }
      if (name === 'partner_id') {
        if (value.partner_id) {
          getPeople(value.partner_id);
        }
      }
      if (name === 'market') {
        setValue('enquiry_type', undefined);
      }
      if (name === 'outcome') {
        if (value.outcome) {
          setValue('status', 'closed');
        }
      }
      if (name === 'exposure_gross') {
        const isHaveExposure =
          ((floorNumber(value?.exposure_gross) as number) || 0) > 0;
        if (isHaveExposure) {
          if (isEmpty(value.exposure_currency)) {
            showErrorExposureCurrency();
          } else {
            clearFormErrors('exposure_currency');
          }
        } else {
          setValue('exposure_currency', undefined);
          clearFormErrors('exposure_currency');
        }
      }

      if (name === 'individual_limits') {
        const isHaveIndividualCurrency =
          ((floorNumber(value?.individual_limits) as number) || 0) > 0;

        if (isHaveIndividualCurrency) {
          if (isEmpty(value.individual_currency)) {
            showErrorIndividualCurrency();
          } else {
            clearFormErrors('individual_currency');
          }
        } else {
          setValue('individual_currency', undefined);
          clearFormErrors('individual_currency');
        }
      }

      if (name === 'protection') {
        if (((floorNumber(value?.protection) as number) || 0) < 1) {
          setValue('protection_unit', undefined);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, mandateListe]);

  const submit = async (data: InputFormEnquiries) => {
    const exportInsuredPercent =
      (data.insured_percent as string)?.replaceAll('% ', '') || '0';

    const params = {
      ...data,
      insured_percent: parseFloat(exportInsuredPercent),
      start_date: exportDateWs(data.start_date) as string,
      end_date: exportDateWs(data.end_date) as string,
      support1_user_id: data.support1_user_id || null,
      support2_user_id: data?.support2_user_id || null,
      partner_id: data?.partner_id || null,
      partner_contact_id: data?.partner_contact_id || null,
      exposure_gross: floorNumber(data.exposure_gross),
      individual_limits: floorNumber(data.individual_limits),
      deductible: floorNumber(data.deductible),
      protection: floorNumber(data.protection),
    };

    const isUpdated = checkIsEdit(data.id);
    if (isUpdated) {
      const objectDiff = getObjectDifferences(props.defaultFormValues, params);
      try {
        const response = await updateEnquiries(
          setEmptyUndefinedEditValues({ ...objectDiff, id }),
        ).unwrap();
        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Update Enquiry',
              body: `Enquiry ${enquiryNumber} has been updated`,
              show: true,
            }),
          );
          props.closingModal(null);
        }
      } catch (error) {
        props.closingModal(null);
      }
      resetForm();
    } else {
      try {
        const response = await createEnquiries(
          nullingEmptyValues(params),
        ).unwrap();

        if (response && response.success) {
          dispatch(
            updateNotificationMessage({
              title: 'Add Enquiry',
              body: `Enquiry ${response.enquiry?.number} has been added`,
              show: true,
            }),
          );
          props.closingModal(response.enquiry);
        }
      } catch (error) {
        props.closingModal(null);
      }
      resetForm();
    }
  };

  const runSearch = useCallback(
    async (key: keyof InputFormEnquiries, value: string) => {
      if (value !== undefined && value !== null) {
        if (key === 'mandate_id') {
          await handleMandateId(value);
        }
        if (key === 'partner_id') {
          await handlCounterPartie(value, 'partner');
        }
      }
    },
    [],
  );

  React.useEffect(() => {
    if (props.defaultFormValues) {
      const selectedEnquiry = props.defaultFormValues;

      if (selectedEnquiry.mandate_id) {
        handleMandateByLegalEntity(selectedEnquiry.mandate_id);
      }
      if (selectedEnquiry.partner_id) {
        getPeople(selectedEnquiry.partner_id);
        handlePartnerCounterParty(selectedEnquiry.partner_id);
      }
      reset({
        ...selectedEnquiry,
        exposure_gross: amountFormat(
          selectedEnquiry.exposure_gross || 0,
        ) as string,
        individual_limits: amountFormat(
          selectedEnquiry.individual_limits || 0,
        ) as string,
        deductible:
          (amountFormat(selectedEnquiry.deductible || 0) as string) || 0,
        protection: amountFormat(selectedEnquiry.protection || 0) as string,
        insured_percent: selectedEnquiry.insured_percent
          ? '% ' + Number(selectedEnquiry.insured_percent).toFixed(1) || 0
          : '% 0.0',
        end_date: exportDateUi(selectedEnquiry.end_date) as string,
        start_date: exportDateUi(selectedEnquiry.start_date) as string,
      });
    }
  }, [props.defaultFormValues]);

  React.useEffect(() => {
    const subscription = watch((value) => {
      let isCompletedOutcome = true;
      let isCompletedCurrencyExposure = true;
      let isCompletedCurrencyIndividual = true;
      const isHaveExposure =
        ((floorNumber(value?.exposure_gross) as number) || 0) > 0;

      const isHaveIndividualCurrency =
        ((floorNumber(value?.individual_limits) as number) || 0) > 0;

      if (isHaveExposure) {
        isCompletedCurrencyExposure = isExist(value.exposure_currency)
          ? true
          : false;
      }

      if (isHaveIndividualCurrency && value.status !== 'closed') {
        isCompletedCurrencyIndividual = isExist(value.individual_currency)
          ? true
          : false;
      }

      if (value.status === 'closed' && isExist(value.outcome)) {
        isCompletedOutcome = true;
      } else if (value.status === 'closed' && !isExist(value.outcome)) {
        isCompletedOutcome = false;
      }

      const isCompletedRequired =
        value.mandate_id &&
        value.client_legal_entity_id &&
        value.market &&
        value.lead_user_id &&
        value.enquiry_type &&
        value.start_date &&
        isCompletedOutcome &&
        isCompletedCurrencyExposure &&
        isCompletedCurrencyIndividual &&
        value.status &&
        value.lead_user_id;
      
      setIsCompletedForm(isCompletedRequired ? true : false);
      setIsEdit(isExist(value.id) && value.id !== -1 ? true : false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const showMandate = (id: string | number) => {
    const found = (mandateListe || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'mandate',
        }),
      );
    }
  };

  const showLegalEntity = (idLgl: number | null) => {
    const found = (legalEntityList || []).find(
      (el) => el.id?.toString() === idLgl?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showCofarcoTeams = async (id: string | number) => {
    const found = (optionUserNameOwner || []).find((el) => el.id === id);
    if (found) {
      const resp = await getCofarcoTeam({ id: found?.id }).unwrap();

      if (resp.user && resp.success) {
        const userResult: any = resp.user;
        const tempRigth = userResult?.rights || {};
        const tempDataSourceRigth = [];
        for (const property in tempRigth) {
          tempDataSourceRigth.push({
            ...tempRigth[property],
            object: property,
          });
        }
        dispatch(
          updateModalAction({
            data: {
              ...userResult,
              entry_date: exportDateUi(userResult?.entry_date) as string,
              departure_date: exportDateUi(
                userResult?.departure_date,
              ) as string,
              username: concatString(
                userResult?.people?.firstname || '',
                userResult?.people?.lastname || '',
                ' ',
              ),
              history: userResult?.rights?.['history']?.read,
              data_source_rigth: tempDataSourceRigth,
            },
            isAdd: true,
            type: 'cofarco_team',
          }),
        );
      }
    }
  };

  const showLegalEntityClientId = async () => {
    const client = selectedMandate && selectedMandate?.client;
    if (client?.id) {
      const resp = await getClientById({
        id: client?.id,
      }).unwrap();
      if (resp?.success) {
        dispatch(
          updateModalAction({
            data: resp?.counterparty,
            isAdd: true,
            type: 'client',
          }),
        );
      }
    }
  };

  const showPartner = (id: number) => {
    const found = (partenerList || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'partner',
        }),
      );
    }
  };

  const showPeople = (id: number) => {
    const found = (partenersPeople || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'people',
        }),
      );
    }
  };

  const showTopObligorEntity = async () => {
    const legalId = selectedMandateById?.entity_obligor?.id;
    if (legalId) {
      const resp = await getLegalEntityById({
        id: legalId?.toString(),
      }).unwrap();
      if (resp?.success) {
        dispatch(
          updateModalAction({
            data: {
              ...resp?.legalentity,
              group_id: resp?.legalentity?.group?.id,
              group_name: resp?.legalentity?.group?.name,
            },
            isAdd: true,
            type: 'legal_entity',
          }),
        );
      }
    }
  };

  const onAddAttachment = (files: FileList | null) => {
    if (files && files.length > 0) {
      const filteType = files?.[0]?.type;
      isAcceptedFile(filteType) &&
        addAttachment({ id, data: files?.[0] }).then(() =>
          refetchGetEnquiryAttachement(),
        );
      return;
    }
  };

  const handlePrompt = () => {
    setShowPrompt(!showPrompt);
  };

  const handlePromptEnquiry = () => {
    setShowPromptEnquiry(!showPromptEnquiry);
  };

  React.useEffect(() => {
    if (isSuccessCreateMarket) {
      if (showPrompt) handlePrompt();
      setSelectedMarket(null);
    }
  }, [isSuccessCreateMarket]);

  const savedIndicatoin = (enquiry_id: number, underwriter_id: number) => {
    const params = {
      amount: 0,
      approached: false,
      channel: '',
      comments: null,
      contact_id: null,
      contacted_date: null,
      currency: '',
      enquiry_id: enquiry_id,
      id: -2,
      indication_date: null,
      indication_price: '',
      indication_type: '',
      underwriter_id: underwriter_id,
    };
    addMarket(params);
  };

  const addClick = () => {
    if (selectedMarket && props?.defaultFormValues?.id) {
      savedIndicatoin(
        props?.defaultFormValues?.id as number,
        selectedMarket?.id,
      );
    }
  };

  const addPanel = async (allId: Array<number>, enqId: number) => {
    try {
      const results = await Promise.all(
        allId?.map((undId: number) =>
          addPanelWs(url.indication.list, undId, enqId),
        ),
      ); // some more awaits below this point
      return results;
    } catch (err) {
      return [];
    }
  };

  const addClickPanel = async () => {
    if (selectedEnquiry?.id) {
      const resp = await getIndicationIdInPanel({
        sort: undefined,
        filter: undefined,
        id: selectedEnquiry.id,
      }).unwrap();
      if (resp?.indication?.length < 1) {
        dispatch(
          updateNotificationMessage({
            title: 'Add Panel Error',
            body: `No Indication attached to this`,
            show: true,
          }),
        );
        return;
      }
      const allUnderId: Array<number> = resp?.indication
        ?.map((el) => el.underwriter_id)
        ?.filter((id) => isExist(id));
      await addPanel(allUnderId, props?.defaultFormValues?.id as number);
      dispatch(baseApi.util.invalidateTags(['INDICATION_MARKET_LIST' as any]));
      handlePromptEnquiry();
    }
  };

  const handleShowOtherModal = (id: any, func: FunctionName) => {
    if (isDirty && isEdit) {
      setIsShowModalUnsavedChange(true);
      setShowOtherModal({ id, func });
    } else {
      handleLeaveModalWithoutSave(id, func);
    }
  };

  const handleLeaveModalWithoutSave = (id: any, func: FunctionName) => {
    isEdit && resetForm();
    showFunctions(id, func);
    setIsShowModalUnsavedChange(false);
  };

  const showFunctions = useCallback(
    (id: any, func: FunctionName) => {
      switch (func) {
        case 'mandate':
          showMandate(id);
          break;
        case 'legal_entity':
          showLegalEntity(id);
          break;
        case 'people':
          showPeople(id);
          break;
        case 'partner':
          showPartner(id);
          break;
        case 'cofarco_team':
          showCofarcoTeams(id);
          break;
        case 'legal_entity_client_id':
          showLegalEntityClientId();
          break;
        case 'top_obligor_entity':
          showTopObligorEntity();
          break;
        default:
          break;
      }
    },
    [showLegalEntity, showPeople, showPartner, showCofarcoTeams],
  );

  return {
    resetForm,
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    isDirty,
    submit,
    isEdit,
    runSearch,
    mandateListe,
    legalEntityList,
    setSelectedMandateById,
    selectedMandateById,
    partenerList,
    isPartenerLoading: isGetCounterLoading || isSearchCoLoading,
    isMandateLoading: isGetMandateLoading || isGetMandatBIdLoading,
    partenersPeople,
    ownerList,
    optionUserNameOwner,
    mandate_id,
    isCompletedForm,
    modalTitle,
    selectedMandate,
    showMandate,
    showCofarcoTeams,
    clearFormErrors,
    showLegalEntity,
    showLegalEntityClientId,
    showPartner,
    showPeople,
    showTopObligorEntity,
    setFormError,
    isLoadingAttachement:
      isLoadingAttachement ||
      isLoadingGetEnquiryAttachement ||
      isFetchingGetEnquiryAttachement,
    onAddAttachment,
    handlePrompt,
    showPrompt,
    addClick,
    selectedMarket,
    setSelectedMarket,
    showPromptEnquiry,
    setShowPromptEnquiry,
    isLoadingCreateMarket,
    handlePromptEnquiry,
    selectedEnquiry,
    setSelectedEnquiry,
    addClickPanel,
    handleMandateId,
    attachments,
    refetchGetEnquiryAttachement,
    handleShowOtherModal,
    handleLeaveModalWithoutSave,
    isShowModalUnsavedChange,
    setIsShowModalUnsavedChange,
    showOtherModal,
    dirtyFields,
  };
}
