import React, { useState } from 'react';
import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  amountFormat,
  floorNumber,
  isNumber,
} from '../../utils/helper-function';
import { ReactComponent as Warning } from '../../assets/img/Warning.svg';

interface CofarcoFormNumberProps extends FormControlProps {
  register: any;
  watch: any;
  setValue: any;
  key_form: string;
  errors?: any;
  txtError?: string;
  isRequired?: boolean;
  txtErrorValidate?: string;
  class?: string;
  disabled?: boolean;
  maxValue?: number;
  acceptFloat?: boolean;
  minLength?: number;
  isInteger?: boolean;
}

export default function CofarcoFormNumber(props: CofarcoFormNumberProps) {
  const { acceptFloat = true, minLength, isInteger } = props;
  const valueForm = props.watch(props.key_form);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const formatValue = (value: string) => {
    if(isInteger){
      return +value || 0;
    }
    let val = amountFormat(+value || 0, acceptFloat ? 2 : 0);
    if (!minLength) return val;

    while (val.length < minLength) {
      val = '0' + val;
    }

    return val;
  };

  return (
    <Form.Label className={`w-100`}>
      <div
        className={`d-flex  elementFormNumber flex-row mx-0  py-2 my-0 ${
          props?.disabled ? 'disabled-content' : ''
        }`}
      >
        <div className={`d-flex flex-fill `}>
          <FormControl
            disabled={props.disabled}
            type="text"
            value={isInteger ? formatValue(valueForm) : valueForm}
            className={`flex-fill formNumber rounded-0`}
            {...props.register(props?.key_form, {
              onChange: (e: any) => {
                const value: any = e.target.value;
                if (value) {
                  const d = props.maxValue
                    ? Number(value) <= props.maxValue && isNumber(value)
                    : isNumber(value);
                  if (d && acceptFloat) {
                    const parsedValue = value
                      .replace(/[^0-9.]/g, '')
                      .replace('.', 'x')
                      .replace(/\./g, '')
                      .replace('x', '.');
                    props.setValue(props?.key_form, parsedValue ?? '0', {
                      shouldDirty: true,
                    });
                    return;
                  }
                  if (d && !acceptFloat) {
                    const parsedValue = value
                      .replace(/[^0-9.]/g, '')
                      .replace('.', '')
                      .replace(/\./g, '')
                      .replace('x', '.');
                    props.setValue(props?.key_form, parsedValue ?? '0', {
                      shouldDirty: true,
                    });
                    return;
                  }
                  props.setValue(props?.key_form, valueForm, {
                    shouldDirty: true,
                  });
                  return;
                }
                props.setValue(props?.key_form, undefined, {
                  shouldDirty: true,
                });
              },
            })}
            onBlur={(e) => {
              setIsFocused(false);
              const value: any = e.target.value;
              props.setValue(props?.key_form, formatValue(value), {
                shouldDirty: true,
              });
            }}
            onFocus={() => {
              setIsFocused(true);
              const floorValue = floorNumber(valueForm?.toString() as string);
              props.setValue(props?.key_form, floorValue?.toString()?.trim(), {
                shouldDirty: true,
              });
            }}
          />
        </div>
        <div
          className={`d-flex justify-content-end flex-fill ${
            props?.disabled ? 'disabled' : ''
          }`}
        >
          <div
            className="flex flex-colummn justify-content-center pt-2 pe-2"
            style={{ color: '#D9D9D9' }}
          >
            <span>|</span>
          </div>
          <div className={`d-flex flex-column justify-content-between`}>
            <div className="py-0 my-0">
              <IoIosArrowUp
                onClick={() => {
                  const floorValue = valueForm?.toString()?.includes(',')
                    ? valueForm?.toString()?.replaceAll(',', '')
                    : valueForm;
                  props.setValue(
                    props?.key_form,
                    parseInt(floorValue?.toString() || '0') + 1,
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                style={{
                  fontSize: 16,
                  // marginTop: 16,
                  cursor: 'pointer',
                  color: '#851001',
                }}
              />
            </div>
            <div className="py-0 my-0">
              <IoIosArrowDown
                onClick={() => {
                  const floorValue = valueForm?.toString()?.replaceAll(',', '');

                  props.setValue(
                    props?.key_form,
                    Math.max(parseInt(floorValue?.toString() || '0') - 1, 0),
                    {
                      shouldDirty: true,
                    },
                  );
                }}
                style={{
                  fontSize: 16,
                  // marginBottom: 8,
                  cursor: 'pointer',
                  color: '#851001',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isFocused && Number(props.maxValue) > 0 && (
        <div style={{ position: 'absolute' }}>
          <Warning style={{ fontSize: 4 }} width={10} />{' '}
          <span style={{ fontSize: 12 }}>
            {`The maximum value for this field is ${props.maxValue}`}
          </span>
        </div>
      )}
    </Form.Label>
  );
}
