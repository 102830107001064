import { Indication } from '../Indication/indication';
import { ConterParty } from '../conterParty';
import { EntityDeals } from '../deals/deals';
import { EntityEnquiries } from '../enquiries';
import { EntityMandate } from '../mandates/mandates';
import { People } from '../people';
import {
  EntityNotes,
  InputFormsNotesDetails,
} from '../segregatedaccounts/notes';

export type DashboardRev = {
  rev: number;
  success: boolean;
};

export type DashboardRum = {
  rum: number;
  success: boolean;
};

export type DashboardRue = {
  rue: number;
  success: boolean;
};

export type DashboardTransaction = {
  total_enquiry: number;
  total_deal: number;
  total_lead: number;
  total_mandate: number;
  total_offer: number;
  success: boolean;
};

export type DashboardClient = {
  total_active_clients: number;
  total_prospects: number;
  success: boolean;
};

export type DashboardMarket = {
  indirect: number;
  direct: number;
  success: boolean;
};

//Reminder types
export type ReminderDeal = EntityDeals & {
  color: string | null | undefined;
  settlement_date: string | null | undefined;
};

// Premium

export type ReminderNote = Omit<EntityNotes, 'legal_entity'> & {
  group_id: number;
  name: string;
  type: string;
  address: string;
  zip: string;
  city: string;
  country_id: string;
  vat_number: string;
  firm_number: string;
  ibans: string;
  ratings: string | null;
  color: null;
  legal_entity: string;
  payment_date: string;
  settlement_date: string;
};

export type DashboardReminderData = {
  expiry?: ReminderDeal[];
  nonRenewal?: ReminderDeal[];
  premium: ReminderNote[];
  total: number;
  success: true;
};

export type DashboadWhatsNewChange = {
  indication_date: string;
  enquiry_id: number;
  underwriter_id: number;
  approached: number;
  contact_id: number | null;
  contacted_date: number | null;
  channel: string;
  indication_type: string;
  amount: number;
  currency: string;
  indication_price: string;
  comments: string | null;
};

export type DashboadWhatsNewChangePerson = {
  name: string;
  address: string;
  zip: string;
  country_id: string;
  vat_number: string;
  firm_number: string;
  ibans: string | null;
  ratings: string | null;
};

export type CofarcoInformations = {
  team: string;
  clientStatus: string;
  managerUserId: number;
};

export enum CounterPartyBusiness {
  underwriter = 'underwriter',
  client = 'client',
  financier = 'financier',
  partner = 'partner',
  obligor = 'obligor',
}

export type DashboadWhatsNewCommon = {
  log_at: string;
  user_id: number;
  object_id: number;
  object_type: string;
  type: string;
  ip_address: string;
  client_name?: string;
  mandates_indication_name: string;
  mandates_deals_name: string;
  mandates_enquiry_name: string | null;
  mandate_note_name: string | null;
  counterparties_name: string;
  business: CounterPartyBusiness;
  legal_entity_name: string;
  mandate_name: string;
  indication_type: string;
  mandate_status: string;
  outcome: null | string;
  deal_status: string;
  note_status: string;
  enquiries_status?: string;
  avatar_filename: string;
  people_full_name: string;
  stateSeen?: 'seen';
  note_id?: string;
  note_recipient?: string;
  client_name_enquiry?: string;
  client_name_deals?: string;
  client_name_indication?: string;
  clients_name_mandate?: string;
  note_type?: string;
  legal_entity_people_name?: string;
  counterparties_commercial_name?: string;
  mandates_obligor_name?: string;
  mandates_obligor_legal_entity_name?: string;
};

export enum ModelsEnum {
  mandate = 'Mandate',
  enquiry = 'Enquiry',
  counterParty = 'Counterparty',
  deal = 'Deal',
  people = 'People',
  underwriter = 'Underwriter',
  client = 'Client',
  financier = 'Financier',
  partner = 'Partner',
  obligor = 'Obligor',
  indication = 'Indication',
  note = 'Note',
  payment = 'Payment',
  legalEntity = 'legal_entity',
}

export type DashboardWhatsNewDeal = {
  original?: Partial<EntityDeals>;
  changes?: Partial<EntityDeals>;
};

export type DashboardWhatsNewCounterParty = {
  original: Partial<ConterParty>;
  changes: Partial<ConterParty>;
};

export type DashboardWhatsNewEnquiry = {
  original: Partial<EntityEnquiries>;
  changes: Partial<EntityEnquiries>;
};

export type DashboadWhatsNewPeople = {
  original: Partial<People>;
  changes: Partial<People>;
};

export type DashboardWhatsNewIndication = {
  original: Partial<Indication>;
  changes: Partial<Indication>;
};

export type DashboardWhatsNewMandate = {
  original: Partial<EntityMandate>;
  changes: Partial<EntityMandate>;
};

export enum NoteStatus {
  draft = 'draft',
}
export type DashboardWhatsNewNote = {
  original: Partial<InputFormsNotesDetails>;
  changes: Partial<InputFormsNotesDetails>;
};

export type DashboardWhatsNewData = (
  | DashboardWhatsNewDeal
  | DashboardWhatsNewCounterParty
  | DashboardWhatsNewEnquiry
  | DashboadWhatsNewPeople
  | DashboardWhatsNewIndication
  | DashboardWhatsNewMandate
  | DashboardWhatsNewNote
) &
  DashboadWhatsNewCommon;

export type DashboadWhatsNew = {
  success: true;
  whatsNew: DashboardWhatsNewData[];
  total: number;
};

export type WhatsNewSeen = {
  id: number;
  success: boolean;
};

export type TodayAgenda = {
  id: string;
  subject: string;
  webLink: string;
  meetingUrl: string;
  start: string;
  end: string;
  startHour: string;
  endHour: string;
};
export type DashboardTodayAgenda = {
  data: Array<TodayAgenda>;
};

export type CurrenyExchange = {
  currencyFrom: string;
  currencyTo: string;
  rate: number;
  id: number;
};

export type DashboardCurrenyExchange = {
  data: Array<CurrenyExchange>;
};

export type IndustryNews = {
  guid: string;
  note: string;
  created: string;
  updated: string;
  url: string;
};

export type DashboardIndustryNews = {
  data: Array<IndustryNews>;
};
