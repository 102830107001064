import { Col, Container, Form, Row } from "react-bootstrap";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Link } from "react-router-dom";
import { TermConst } from "../../../../@types/common-types";
import {
  EntityOwnerName,
  InputFormMandate,
} from "../../../../@types/mandates/mandates";
import UseConstante from "../../../../hooks/use-constante";
import {
  exportedCountryName,
  exportedDropdownValue,
  isExist,
  makeOptions,
} from "../../../../utils/helper-function";
import {
  DropDownOptionObjectType,
  DropDownOptionType,
} from "../../../common/DropDown/DropdownSelect";
import SelectDropDown from "../../../common/DropDown/SelectDropDown";
import CofarcoFormText from "../../../Form/CofarcoFormText";
import MandateTypeForm from "./MandateTypeForm";
import SourceOfBusinessForm from "./SourceOfBusinessForm";

import { ReactComponent as TeamInactif } from "../../../../assets/img/TeamInactif.svg";
import { ReactComponent as TeamActif } from "../../../../assets/img/TeamActif.svg";
import { ReactComponent as PeopleIncatif } from "../../../../assets/img/PeopleInactif.svg";
import { ReactComponent as PeopleActif } from "../../../../assets/img/PeopleActif.svg";

import { ReactComponent as PartnerIncatif } from "../../../../assets/img/PartnerIncatif.svg";
import { ReactComponent as PartnerActif } from "../../../../assets/img/PartnerActif.svg";

import { ConterParty } from "../../../../@types/conterParty";
import { People } from "../../../../@types/people";
import { LegalEntity } from "../../../../@types/group/types";
import { ReactComponent as LegalentitiesActif } from "../../../../assets/img/LegalentitiesActif.svg";
import { ReactComponent as LegalentitiesInactif } from "../../../../assets/img/LegalentitiesInactif.svg";
import RowContent from "../../../Form/RowContent";
import CofarcoCalendar from "../../../Calendar/CofarcoCalendar";
import { ReactComponent as ClientInactif } from "../../../../assets/img/ClientInactif.svg";
import { ReactComponent as ClientActive } from "../../../../assets/img/ClientActive.svg";
import CofarcoNumberAmount from "../../../Form/CofarcoNumberAmount";
import ModalUnsavedChange from "../../../common/Modal/ModalUnsavedChange";

type IProps = {
  watch: UseFormWatch<InputFormMandate>;
  setValue: UseFormSetValue<InputFormMandate>;
  runSearch: (key: keyof InputFormMandate, value: string) => Promise<void>;
  register: UseFormRegister<InputFormMandate>;
  errors: FieldErrors<InputFormMandate>;
  control: Control<InputFormMandate, any>;

  clientsList: Array<ConterParty>;
  clearFormErrors: UseFormClearErrors<InputFormMandate>;
  setFormError: UseFormSetError<InputFormMandate>;
  isLoading: boolean;
  isLoadingLegalEntity: boolean;
  peoples: Array<People>;
  optionUserNameOwner: Array<EntityOwnerName & { userName: string }>;
  partenerList: Array<ConterParty>;
  partenersPeople: Array<People>;
  legalentity: Array<LegalEntity>;
  showOtherModal: any;
  provider: any;
};

export default function Forms({ provider, showOtherModal, ...props }: IProps) {
  const in_charge_team = props.watch("in_charge_team");
  const id = props.watch("id");
  const activity = props.watch("activity");
  const number = props.watch("number");
  const partner_id = props.watch("partner_id");
  const partner_contact_id = props.watch("partner_contact_id");
  const main_people_client_id = props.watch("main_people_client_id");
  const most_senior_client_id = props.watch("most_senior_client_id");
  const targeted_amount = props.watch("targeted_amount");
  const currency = props.watch("currency");
  const in_charge_user_id = props.watch("in_charge_user_id");
  const brokerage = props.watch("brokerage");
  const phase = props.watch("phase");
  const status = props.watch("status");
  const client_id = props.watch("client_id");
  const mandate_type = props.watch("mandate_type");
  const business_source = props.watch("business_source");
  const project_type = props.watch("project_type");
  const obligor_legal_entity_id = props.watch("obligor_legal_entity_id");
  const client = props.watch("client_id");
  const fee = props.watch("fee");

  const terms = UseConstante();

  const showErrorCurrency = () => {
    props.setFormError("currency", {
      message: "Currency is required",
    });
  };

  const txtUnder = "Underlying \nTransaction /Asset";

  const isRequiredEndDate =
    phase === "mandate" && isExist(status) && status !== "executing";

  const isRequiredInceptionDate = phase === "mandate";

  // const isRequiredMainObligor = mandate_type === 'insurance';
  const isRequiredMainObligor = false;

  const countryOptions =
    (provider.responseCountry?.country || []).map((el: any) => ({
      key: el.key,
      value: el.name,
    })) || [];

  const getStatus = () => {
    if (phase) {
      return terms
          .getConst(TermConst.mandateStatus)
          ?.filter((el: any) => el?.dependences === phase);
    }
    return [];
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline pt-2`}>
              <Form.Label className="txt">Mandate ID</Form.Label>

              <div className="d-flex flex-column w-100">
                <span>
                  <b>{id ? number : "New Mandate"}</b>
                </span>
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline required w-100">
              <Form.Label className="txt-with-dropdown">Client </Form.Label>
              <div className="d-flex d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  modalId="mandate-client_id"
                  id="client_id_mandate"
                  preValue={exportedDropdownValue(
                    makeOptions(props.clientsList || [], "name", "id"),
                    client
                  )}
                  {...props.register}
                  iconsClass="pt-2"
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {client ? (
                        <ClientActive
                          color="#EB5D57"
                          onClick={() =>
                            provider.handleShowOtherModal(client, "client")
                          }
                        />
                      ) : (
                        <ClientInactif color="#EB5D57" />
                      )}
                    </span>
                  }
                  loading={props.isLoading}
                  onSearch={(d, v) => props.runSearch("client_id", v)}
                  options={makeOptions(props.clientsList || [], "name", "id")}
                  onSelectOption={(e: DropDownOptionType) => {
                    props.setValue(
                      "client_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    );
                    if (e) {
                      props.clearFormErrors("client_id");
                      return;
                    }
                    props.setFormError("client_id", {
                      message: "Client is required",
                    });
                  }}
                  onBlur={() => {
                    if (!isExist(client_id)) {
                      props.setFormError("client_id", {
                        message: "Client is required",
                      });
                    }
                  }}
                  error={props.errors?.client_id?.message}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <CofarcoFormText
                errors={props.errors}
                isRequired
                key_form="name"
                class="w-100 align-items-baseline"
                label="Mandate Name"
                register={props.register("name", {
                  required: true,
                })}
                txtError="Mandate name is required"
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <MandateTypeForm
              register={props.register}
              setValue={(v) => {
                props.setValue("mandate_type", v, {
                  shouldDirty: true,
                });
                if (v) {
                  props.clearFormErrors("mandate_type");
                  return;
                }
                props.setFormError("mandate_type", {
                  message: "Mandate Type is required",
                });
              }}
              watch={props.watch}
              onBlur={() => {
                if (!isExist(mandate_type)) {
                  props.setFormError("mandate_type", {
                    message: "Mandate Type is required",
                  });
                }
              }}
              errors={props.errors?.mandate_type?.message}
            />
          </RowContent>

          <RowContent>
            <Form.Group className={`d-flex align-items-baseline pt-2`}>
              <Form.Label className="txt">Mandate Activity </Form.Label>

              <div className="d-flex flex-row w-100 allOneOff">
                <Link
                  to="#"
                  className={`one ${activity === "on" ? "active-on" : ""}`}
                >
                  On
                </Link>
                <Link
                  to="#"
                  className={`off ${activity === "off" ? "active-off" : ""}`}
                >
                  Off
                </Link>
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <SourceOfBusinessForm
              register={props.register}
              watch={props.watch}
              setValue={(v) => {
                props.setValue("business_source", v, {
                  shouldDirty: true,
                });
                if (v) {
                  props.clearFormErrors("business_source");
                  return;
                }
                props.setFormError("business_source", {
                  message: "Source of Business is required",
                });
              }}
              onBlur={() => {
                if (!isExist(business_source)) {
                  props.setFormError("business_source", {
                    message: " Source of Business is required",
                  });
                }
              }}
              errors={props.errors?.business_source?.message}
            />
          </RowContent>

          <RowContent>
            <Form.Group className={`d-flex align-items-start`}>
              <CofarcoFormText
                errors={props.errors}
                key_form="project_activity"
                class="w-100 align-items-start"
                label="Underlying Transanction /Activity /Project"
                register={props.register("project_activity", {})}
              />
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className={`d-flex align-items-start`}>
              <Form.Label className="txt-with-dropdown">{txtUnder}</Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  modalId="mandate"
                  noSearch
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.projectType),
                    project_type
                  )}
                  options={terms?.getConst(TermConst.projectType)}
                  onSelectOption={(e) =>
                    props.setValue(
                      "project_type",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  {...props.register("project_type")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt txt-center-fix">
                Targeted Amount
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <CofarcoNumberAmount
                  register={props.register}
                  setValue={props.setValue}
                  watch={props.watch}
                  key_form="targeted_amount"
                  unitFormat={2}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt-with-dropdown">Currency</Form.Label>

              <div className={`d-flex w-100 flex-column `}>
                <SelectDropDown
                  noPadding
                  modalId="mandate"
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.currency),
                    currency
                  )}
                  disabled={!!((targeted_amount as number) < 1)}
                  noSearch
                  options={terms?.getConst(TermConst.currency)}
                  onSelectOption={(e) => {
                    props.setValue(
                      "currency",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    );
                    const isCleared =
                      isExist(targeted_amount) &&
                      !isExist((e as DropDownOptionObjectType).key);
                    if (isCleared) {
                      showErrorCurrency();
                      return;
                    }

                    props.clearFormErrors("currency");
                  }}
                  onBlur={() => {
                    const isEmptyCurrency =
                      isExist(targeted_amount) && !isExist(currency);
                    if (isEmptyCurrency) {
                      showErrorCurrency();
                    }
                  }}
                  error={props.errors?.currency?.message}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt-with-dropdown">
                Main Country
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  error={provider.errors?.country_name?.message}
                  noPadding
                  modalId="modal-main-country"
                  preValue={exportedCountryName(
                    provider.responseCountry?.country || [],
                    provider.country as string
                  )}
                  onSearch={(d, e) =>
                    provider.runSearch("main_country_id", e, d)
                  }
                  onSelectOption={(e) => {
                    provider.setValue(
                      "main_country_name",
                      (e as DropDownOptionObjectType).value,
                      { shouldDirty: true }
                    );
                    provider.setValue(
                      "main_country_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    );
                  }}
                  options={countryOptions}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt-with-dropdown">
                Main Obligor{" "}
                {isRequiredMainObligor && (
                  <span className="span-required">*</span>
                )}
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  modalId="mandate"
                  iconsClass="pt-2"
                  error={props.errors?.obligor_legal_entity_id?.message}
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {obligor_legal_entity_id ? (
                        <LegalentitiesActif
                          onClick={() =>
                            provider.handleShowOtherModal(
                              obligor_legal_entity_id,
                              "legal_entity"
                            )
                          }
                        />
                      ) : (
                        <LegalentitiesInactif />
                      )}
                    </span>
                  }
                  preValue={exportedDropdownValue(
                    makeOptions(props.legalentity || [], "name", "id"),
                    obligor_legal_entity_id
                  )}
                  loading={props.isLoadingLegalEntity}
                  onSearch={(d, v) =>
                    props.runSearch("obligor_legal_entity_id", v)
                  }
                  options={makeOptions(props.legalentity || [], "name", "id")}
                  onSelectOption={(e) => {
                    props.setValue(
                      "obligor_legal_entity_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    );
                    if (e) {
                      props.clearFormErrors("obligor_legal_entity_id");
                      return;
                    }
                    if (isRequiredMainObligor) {
                      props.setFormError("obligor_legal_entity_id", {
                        message: "Main Obligor is required",
                      });
                    }
                  }}
                  onBlur={() => {
                    if (
                      !isExist(obligor_legal_entity_id) &&
                      isRequiredMainObligor
                    ) {
                      props.setFormError("obligor_legal_entity_id", {
                        message: "Main Obligor is required",
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
        </Col>

        <Col>
          <RowContent>
            <Form.Group className={`d-flex align-items-baseline`}>
              <Form.Label className="txt-with-dropdown">
                Mandate Phase
              </Form.Label>

              <div className="d-flex flex-column w-100">
                <SelectDropDown
                  noPadding
                  modalId="mandate"
                  noSearch
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.mandatePhase),
                    phase
                  )}
                  options={terms?.getConst(TermConst.mandatePhase)}
                  onSelectOption={(e) =>
                    props.setValue(
                      "phase",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  {...props.register("phase")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Mandate Status <span className="span-required">*</span>
              </Form.Label>

              <div className={`d-flex flex-column w-100`}>
                <SelectDropDown
                  modalId="mandate-status"
                  noPadding
                  {...props.register("status")}
                  error={props.errors?.status?.message}
                  disabled={!phase}
                  noSearch
                  resetFormNoPreValue={true}
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.mandateStatus),
                    status
                  )}
                  options={getStatus()}
                  onSelectOption={(e) => {
                    props.setValue(
                      "status",
                      (e as DropDownOptionObjectType).key,
                      {
                        shouldDirty: true,
                      }
                    );
                    if (e) {
                      props.clearFormErrors("status");
                      return;
                    }
                    props.setFormError("status", {
                      message: "Mandate status is required",
                    });
                  }}
                  onBlur={() => {
                    console.log("status => "+status);
                    if (!isExist(status)) {
                      props.setFormError("status", {
                        message: "Mandate status is required",
                      });
                    }
                  }}
                />
              </div>
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex" style={{ alignItems: "center" }}>
              <Form.Label className="txt">
                First Discussion Date&nbsp;
                <span className="span-required">*</span>
              </Form.Label>
              <CofarcoCalendar
                control={props.control}
                name="first_discu_date"
                setFormError={props.setFormError}
                clearFormErrors={props.clearFormErrors}
                errors={props.errors}
                txtErrorMsg="First discussion date is required"
                required
                setFormValue={props.setValue}
                watchFormValue={props.watch}
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex" style={{ alignItems: "baseline" }}>
              <Form.Label className="txt">
                Inception Date{" "}
                {isRequiredInceptionDate && (
                  <span className="span-required">*</span>
                )}
              </Form.Label>

              <CofarcoCalendar
                control={props.control}
                name="inception_date"
                clearFormErrors={props.clearFormErrors}
                setFormError={props.setFormError}
                errors={props.errors}
                txtErrorMsg="Inception date is required"
                required={isRequiredInceptionDate ? true : false}
                setFormValue={props.setValue}
                watchFormValue={props.watch}
              />
            </Form.Group>
          </RowContent>
          <RowContent>
            <Form.Group className="d-flex" style={{ alignItems: "baseline" }}>
              <Form.Label className="txt">
                End Date
                {isRequiredEndDate && <span className="span-required">*</span>}
              </Form.Label>

              <CofarcoCalendar
                control={props.control}
                name="end_date"
                clearFormErrors={props.clearFormErrors}
                setFormError={props.setFormError}
                errors={props.errors}
                txtErrorMsg="End date is required"
                required={isRequiredEndDate ? true : false}
                setFormValue={props.setValue}
                watchFormValue={props.watch}
              />
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Client Main Contact
              </Form.Label>

              <div className={`d-flexflex-column w-100 `}>
                <SelectDropDown
                  modalId="mandate"
                  noPadding
                  noSearch
                  iconsClass="pt-2"
                  preValue={exportedDropdownValue(
                    makeOptions(props.peoples || [], "userName", "id"),
                    main_people_client_id || 0
                  )}
                  disabled={
                    !!(props.peoples?.length < 1 || !isExist(client_id))
                  }
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {main_people_client_id ? (
                        <PeopleActif
                          onClick={() =>
                            provider.handleShowOtherModal(
                              main_people_client_id,
                              "people",
                              "clientMainContact"
                            )
                          }
                        />
                      ) : (
                        <PeopleIncatif />
                      )}
                    </span>
                  }
                  options={makeOptions(props.peoples || [], "userName", "id")}
                  onSearch={(d, v) =>
                    props.runSearch("main_people_client_id", v)
                  }
                  onSelectOption={(e) =>
                    props.setValue(
                      "main_people_client_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  {...props.register("main_people_client_id")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Client Senior Contact
              </Form.Label>

              <div className={`d-flexflex-column w-100 `}>
                <SelectDropDown
                  noPadding
                  modalId="mandate"
                  iconsClass="mt-2"
                  noSearch
                  disabled={
                    !!(props.peoples?.length < 1 || !isExist(client_id))
                  }
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {most_senior_client_id ? (
                        <PeopleActif
                          onClick={() =>
                            provider.handleShowOtherModal(
                              most_senior_client_id,
                              "people",
                              "clientMainContact"
                            )
                          }
                        />
                      ) : (
                        <PeopleIncatif />
                      )}
                    </span>
                  }
                  preValue={exportedDropdownValue(
                    makeOptions(props.peoples || [], "userName", "id"),
                    most_senior_client_id?.toString() || "0"
                  )}
                  options={makeOptions(props.peoples || [], "userName", "id")}
                  onSelectOption={(e) =>
                    props.setValue(
                      "most_senior_client_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  {...props.register("most_senior_client_id")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Team in Charge
              </Form.Label>

              <div className="d-flexflex-column w-100 ">
                <SelectDropDown
                  noPadding
                  noSearch
                  modalId="in_charge_team"
                  preValue={exportedDropdownValue(
                    terms?.getConst(TermConst.staffTeam),
                    in_charge_team
                  )}
                  options={terms?.getConst(TermConst.staffTeam)}
                  onSelectOption={(e) =>
                    props.setValue(
                      "in_charge_team",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  {...props.register("in_charge_team")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">Owner</Form.Label>

              <div className="d-flexflex-column w-100">
                <SelectDropDown
                  noPadding
                  noSearch
                  iconsClass="mt-2"
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {in_charge_user_id ? (
                        <TeamActif
                          onClick={() =>
                            provider.handleShowOtherModal(
                              in_charge_user_id,
                              "cofarco_team"
                            )
                          }
                        />
                      ) : (
                        <TeamInactif />
                      )}
                    </span>
                  }
                  modalId="in_charge_user_id"
                  onSelectOption={(e) =>
                    props.setValue(
                      "in_charge_user_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  preValue={exportedDropdownValue(
                    makeOptions(
                      props.optionUserNameOwner || [],
                      "userName",
                      "id"
                    ),
                    in_charge_user_id || 0
                  )}
                  options={makeOptions(
                    props.optionUserNameOwner || [],
                    "userName",
                    "id"
                  )}
                  {...props.register("in_charge_user_id")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">Partner</Form.Label>

              <div className="d-flexflex-column w-100">
                <SelectDropDown
                  noPadding
                  modalId="partner_id"
                  onSearch={(d, v) => props.runSearch("partner_id", v)}
                  iconsClass="pt-2"
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {partner_id ? (
                        <PartnerActif
                          onClick={() =>
                            provider.handleShowOtherModal(partner_id, "partner")
                          }
                        />
                      ) : (
                        <PartnerIncatif />
                      )}
                    </span>
                  }
                  options={makeOptions(props.partenerList || [], "name", "id")}
                  preValue={exportedDropdownValue(
                    makeOptions(props.partenerList || [], "name", "id"),
                    partner_id || 0
                  )}
                  onSelectOption={(e) =>
                    props.setValue(
                      "partner_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  loading={props.isLoading}
                  {...props.register("partner_id")}
                />
              </div>
            </Form.Group>
          </RowContent>

          <RowContent>
            <Form.Group className="d-flex align-items-baseline">
              <Form.Label className="txt-with-dropdown">
                Partner Contact
              </Form.Label>

              <div className={`d-flexflex-column w-100 `}>
                <SelectDropDown
                  noPadding
                  iconsClass="pt-2"
                  noSearch
                  modalId="partner_contact_id"
                  preValue={exportedDropdownValue(
                    makeOptions(props.partenersPeople, "userName", "id"),
                    partner_contact_id || 0
                  )}
                  disabled={!partner_id}
                  icon={
                    <span style={{ cursor: "pointer" }}>
                      {partner_contact_id ? (
                        <PeopleActif
                          onClick={() =>
                            provider.handleShowOtherModal(
                              partner_contact_id,
                              "people",
                              "partnerContact"
                            )
                          }
                        />
                      ) : (
                        <PeopleIncatif />
                      )}
                    </span>
                  }
                  onSelectOption={(e) =>
                    props.setValue(
                      "partner_contact_id",
                      (e as DropDownOptionObjectType).key,
                      { shouldDirty: true }
                    )
                  }
                  onSearch={(d, v) => props.runSearch("partner_contact_id", v)}
                  options={makeOptions(props.partenersPeople, "userName", "id")}
                  {...props.register("partner_contact_id")}
                />
              </div>
            </Form.Group>
          </RowContent>
        </Col>
      </Row>
      <div style={{ height: "1px", backgroundColor: "#DBDDDF" }} />
      <Row>
        <div className="d-flex align-items-baseline pt-4">
          <Form.Label className="-title">Remuneration</Form.Label>
        </div>
        <div className="py-4 d-flex flex-row">
          <div className="flex-1">
            <div className="d-flex  align-items-baseline mb-4">
              <Form.Label className="radio">Brokerage</Form.Label>
              <div style={{ flexDirection: "column", width: "100%" }}>
                <div className="d-flex" style={{ maxHeight: "1.5rem" }}>
                  <div className=" mx-4">
                    <Form.Check
                      type="radio"
                      id="Yes"
                      label="Yes"
                      value={1}
                      checked={brokerage === 1}
                      onChange={(e) =>
                        props.setValue("brokerage", parseInt(e.target.value), {
                          shouldDirty: true,
                        })
                      }
                    />
                  </div>
                  <div className="mx-4">
                    <Form.Check
                      type="radio"
                      id="No"
                      label="No"
                      value={0}
                      checked={brokerage === 0}
                      onChange={(e) =>
                        props.setValue("brokerage", parseInt(e.target.value), {
                          shouldDirty: true,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex  align-items-baseline ">
              <Form.Label className="radio">Fee</Form.Label>
              <div
                style={{ flexDirection: "column", width: "100%" }}
                className="mt-1"
              >
                <div className="d-flex" style={{ maxHeight: "1.5rem" }}>
                  <div className="mx-4">
                    <Form.Check
                      type="radio"
                      id="Yes"
                      label="Yes"
                      value={1}
                      checked={fee === 1}
                      onChange={(e) =>
                        props.setValue("fee", parseInt(e.target.value), {
                          shouldDirty: true,
                        })
                      }
                    />
                  </div>
                  <div className=" mx-4">
                    <Form.Check
                      type="radio"
                      id="No"
                      label="No"
                      value={0}
                      checked={fee === 0}
                      onChange={(e) =>
                        props.setValue("fee", parseInt(e.target.value), {
                          shouldDirty: true,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-3 w-100">
            <Form.Group className="d-flex">
              <div className="d-flexflex-column w-100 mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  className=" w-100"
                  {...provider.register("comments")}
                />
              </div>
            </Form.Group>
          </div>
        </div>
      </Row>

      <ModalUnsavedChange
        dirtiFields={provider.dirtyFields}
        handleCancel={() => provider.setIsShowModalUnsavedChange(false)}
        handleLeave={() =>
          provider.handleLeaveModalWithoutSave(
            showOtherModal.idToShow,
            showOtherModal.showingFunction,
            showOtherModal.type
          )
        }
        showModalUnsaved={provider.isShowModalUnsavedChange}
      />
    </Container>
  );
}
