export const exportedMandates = [
  {
    itemId: 'number',
    text: 'ID',
  },
  {
    itemId: 'client_id',
    text: 'Client',
  },
  {
    itemId: 'name',
    text: 'Mandate Name',
  },
  {
    itemId: 'mandate_type',
    text: 'Mandate Type',
  },
  {
    itemId: 'activity',
    text: 'Activity',
  },
  {
    itemId: 'phase',
    text: 'Mandate Phase',
  },
  {
    itemId: 'status',
    text: 'Mandate Status',
  },
  {
    itemId: 'in_charge_team',
    text: 'Team In Charge',
  },
  {
    itemId: 'in_charge_user_id',
    text: 'Owner',
  },
  {
    itemId: 'project_activity',
    text: 'Underlying Transaction/Activity/Project',
  },
  {
    itemId: 'project_type',
    text: 'Underlying Transaction/Asset',
  },
  {
    itemId: 'targeted_amount',
    text: 'Targeted Amount',
  },
  {
    itemId: 'currency',
    text: 'Currency',
  },
  {
    itemId: 'obligor_legal_entity_id',
    text: 'Main Obligor',
  },

  {
    itemId: 'first_discu_date',
    text: 'First Discution Date',
  },
  {
    itemId: 'inception_date',
    text: 'Inception Date',
  },
  {
    itemId: 'end_date',
    text: 'End Date',
  },
  {
    itemId: 'main_people_client_id',
    text: 'Client Main Contact',
  },
  {
    itemId: 'most_senior_client_id',
    text: 'Client Senior Contact',
  },

  {
    itemId: 'partner_id',
    text: 'Partner',
  },
  {
    itemId: 'partner_contact_id',
    text: 'Partner Contact',
  },
  {
    itemId: 'business_source',
    text: 'Source of buisness',
  },
  {
    itemId: 'created_at',
    text: 'Created Date',
  },
  {
    itemId: 'updated_at',
    text: 'Updated Date',
  },
];
