import type { ColumnsType } from 'antd/es/table';
import warning24pxRed from '../../assets/img/warning_24px.svg';
import time24px from '../../assets/img/time_24px.svg';
import {
  DashboardReminderData,
  ReminderDeal,
  ReminderNote,
} from '../../@types/dashboard';
import { useLazyReminderDealsQuery } from '../../redux/api/ws/dashboard/dashboard';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/redux';
import { updateModalAction } from '../../redux/slice/modalSlice';
import { isCan } from '../../utils/helper-function';
import { Menu } from '../../@types/common-types';
import { useLazyNotesQuery } from '../../redux/api/ws/segregatedaccounts/notes';
import moment from 'moment';

const defaultLimit = 20;

export type ReminderDealsProps = {
  route: string;
  dateIndex: string;
  legalEntityIndex: string;
};

export const useDashboardReminder = ({
  route,
  dateIndex,
  legalEntityIndex,
}: ReminderDealsProps) => {
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState<ReminderDeal[] | ReminderNote[]>([]);
  const dispatch = useAppDispatch();

  const dateIsLessThanToday = (date: string) => {
    if (date !== '' && !moment(date).isValid()) return true;
    return moment(date).isBefore(moment(), 'day');
  };

  const columns: ColumnsType<ReminderDeal | ReminderNote> = [
    {
      dataIndex: dateIndex,
      width: 26,
      key: dateIndex,
      render: (date: string, record) => (
        <img
          width={24}
          height={24}
          src={
            !!record.color || dateIsLessThanToday(record.settlement_date ?? '')
              ? warning24pxRed
              : time24px
          }
        />
      ),
    },
    {
      dataIndex: dateIndex,
      key: dateIndex,
      align: 'center',

      render(value: string, record) {
        return new Date(record.settlement_date ?? '').toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      },
    },
    {
      key: 'number',
      dataIndex: 'number',
    },

    ...(route !== 'premium'
      ? [
          {
            key: 'client_name',
            dataIndex: 'client_name',
          },
        ]
      : []),

    {
      key: legalEntityIndex,
      dataIndex: legalEntityIndex,
      render: (value: string, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '90%',
          }}
        >
          {value || (record as ReminderDeal)?.obligor?.name}
        </div>
      ),
    },
  ];

  const getTableScroll = () =>
    document.querySelector<HTMLElement>(
      '#dashboard-expiry-table .ant-table-body',
    );

  const [laod, { data }] = useLazyReminderDealsQuery();
  const [getNotes] = useLazyNotesQuery();

  const addRows = (data?: DashboardReminderData) => {
    if (!data) return;

    const newRows = (data as any)[route];
    if (newRows.length) {
      setRows([...rows, ...newRows]);

      setTimeout(() => {
        const expTable = getTableScroll();
        if (expTable) {
          expTable.scrollTo({
            behavior: 'smooth',
            top: expTable.scrollHeight - expTable.clientHeight - 150,
          });
        }
      }, 100);
    }
  };

  const scrollHandler = (event: any) => {
    const maxScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentScroll = event.target.scrollTop;

    if (currentScroll === maxScroll) {
      const pageCount = (data?.total || 0) / defaultLimit;
      if (page < pageCount) {
        const nexPage = page + 1;
        setPage(nexPage);
        laod({ limit: defaultLimit, page: nexPage, route }).then((res) => {
          addRows(res.data);
        });
      }
    }
  };

  useEffect(() => {
    const expTable = getTableScroll();
    if (expTable) {
      expTable.addEventListener('scroll', scrollHandler);

      return () => {
        // Cleanup: remove the scroll event listener when the component is unmounted
        expTable.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [page, rows]);

  useEffect(() => {
    let ignore = false;
    if (page == 1 && !rows.length) {
      laod({ limit: defaultLimit, page, route }).then((res) => {
        if (!ignore) {
          addRows(res.data);
        }
      });
    }
    return () => {
      ignore = true;
    };
  }, []);

  const editRow = (record: ReminderDeal | ReminderNote) => {
    if (route !== 'premium') {
      if (isCan('write', Menu.deal) || isCan('read', Menu.deal)) {
        dispatch(
          updateModalAction({
            data: record,
            isAdd: true,
            type: 'deals',
          }),
        );
      }
    } else {
      if (isCan('write', Menu.note) || isCan('read', Menu.note)) {
        getNotes({
          limit: 1,
          filter: `[{"property":"id","value":${record.id},"operator":"="}]`,
          page: 1,
          start: 0,
          sort: '',
        }).then(({ data }) => {
          if (data?.note?.length) {
            dispatch(
              updateModalAction({
                data: data.note.at(0),
                isAdd: true,
                type: 'notes_details',
              }),
            );
          }
        });
      }
    }
  };

  return { columns, editRow, rows };
};
